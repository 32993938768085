export const countries = [
    {
      "id": "004",
      "name": "AFGANISTÁN",
      "translationKey": "COUNTRY.AFG",
      "disabled": true,
      "phone": "93",
      "ISOCode": "AFG",
      "flag": "./assets/media/svg/custom-flags/af.svg",
      "code": "AF",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "AFN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "AFGHANISTAN",
      "nameEn": "AFGHANISTAN",
      "config": {
        "currency": "AFN",
        "language": "es",
        "timeZone": "Asia/Kabul"
      }
    },
    {
      "id": "008",
      "name": "ALBANIA",
      "translationKey": "COUNTRY.ALB",
      "disabled": true,
      "phone": "355",
      "ISOCode": "ALB",
      "flag": "./assets/media/svg/custom-flags/al.svg",
      "code": "AL",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "ALL",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ALBANIA",
      "nameEn": "ALBANIA",
      "config": {
        "currency": "ALL",
        "language": "es",
        "timeZone": "Europe/Tirane"
      }
    },
    {
      "id": "012",
      "name": "ALGERIA",
      "translationKey": "COUNTRY.DZA",
      "disabled": true,
      "phone": "213",
      "ISOCode": "DZA",
      "flag": "./assets/media/svg/custom-flags/dz.svg",
      "code": "DZ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "DZD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ALGERIA",
      "nameEn": "ALGERIA",
      "config": {
        "currency": "DZD",
        "language": "es",
        "timeZone": "Africa/Algiers"
      }
    },
    {
      "id": "016",
      "name": "SAMOA AMERICANA",
      "translationKey": "COUNTRY.ASM",
      "disabled": true,
      "phone": "1684",
      "ISOCode": "ASM",
      "flag": "./assets/media/svg/custom-flags/as.svg",
      "code": "AS",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "AMERICAN SAMOA",
      "nameEn": "AMERICAN SAMOA",
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "Pacific/Pago_Pago"
      }
    },
    {
      "id": "020",
      "name": "ANDORRA",
      "translationKey": "COUNTRY.AND",
      "disabled": true,
      "phone": "376",
      "ISOCode": "AND",
      "flag": "./assets/media/svg/custom-flags/ad.svg",
      "code": "AD",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ANDORRA",
      "nameEn": "ANDORRA",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Andorra"
      }
    },
    {
      "id": "024",
      "name": "ANGOLA",
      "translationKey": "COUNTRY.AGO",
      "disabled": true,
      "phone": "244",
      "ISOCode": "AGO",
      "flag": "./assets/media/svg/custom-flags/ao.svg",
      "code": "AO",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "AOA",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ANGOLA",
      "nameEn": "ANGOLA",
      "config": {
        "currency": "AOA",
        "language": "es",
        "timeZone": "Africa/Luanda"
      }
    },
    {
      "id": "660",
      "name": "ANGUILA",
      "translationKey": "COUNTRY.AIA",
      "disabled": true,
      "phone": "1264",
      "ISOCode": "AIA",
      "flag": "./assets/media/svg/custom-flags/ai.svg",
      "code": "AI",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XCD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ANGUILLA",
      "nameEn": "ANGUILLA",
      "config": {
        "currency": "XCD",
        "language": "es",
        "timeZone": "America/Anguilla"
      }
    },
    {
      "id": "028",
      "name": "ANTIGUA Y BARBUDA",
      "translationKey": "COUNTRY.ATG",
      "disabled": true,
      "phone": "1268",
      "ISOCode": "ATG",
      "flag": "./assets/media/svg/custom-flags/ag.svg",
      "code": "AG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XCD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ANTIGUA AND BARBUDA",
      "nameEn": "ANTIGUA AND BARBUDA",
      "config": {
        "currency": "XCD",
        "language": "es",
        "timeZone": "America/Antigua"
      }
    },
    {
      "id": "032",
      "name": "ARGENTINA",
      "translationKey": "COUNTRY.ARG",
      "disabled": false,
      "phone": "54",
      "ISOCode": "ARG",
      "flag": "./assets/media/svg/custom-flags/ar.svg",
      "code": "AR",
      "config": {
        "currency": "ARS",
        "language": "es",
        "timeZone": "America/Buenos_Aires"
      },
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "ARS",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ARGENTINA",
      "nameEn": "ARGENTINA"
    },
    {
      "id": "051",
      "name": "ARMENIA",
      "translationKey": "COUNTRY.ARM",
      "disabled": true,
      "phone": "374",
      "ISOCode": "ARM",
      "flag": "./assets/media/svg/custom-flags/am.svg",
      "code": "AM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "AMD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ARMENIA",
      "nameEn": "ARMENIA",
      "config": {
        "currency": "AMD",
        "language": "es",
        "timeZone": "Asia/Yerevan"
      }
    },
    {
      "id": "531",
      "name": "CURAZAO",
      "translationKey": "COUNTRY.CUW",
      "disabled": false,
      "phone": "599",
      "ISOCode": "CUW",
      "flag": "./assets/media/svg/custom-flags/cw.svg",
      "code": "CW",
      "currencies": [
        {
          "code": "ANG",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CURAÇAO",
      "nameEn": "CURAÇAO",
      "config": {
        "currency": "ANG",
        "language": "en",
        "timeZone": "America/Curacao"
      }
    },
    {
      "id": "533",
      "name": "ARUBA",
      "translationKey": "COUNTRY.ABW",
      "disabled": true,
      "phone": "297",
      "ISOCode": "ABW",
      "flag": "./assets/media/svg/custom-flags/aw.svg",
      "code": "AW",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "AWG",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ARUBA",
      "nameEn": "ARUBA",
      "config": {
        "currency": "AWG",
        "language": "es",
        "timeZone": "America/Aruba"
      }
    },
    {
      "id": "036",
      "name": "ISLAS COCOS (KEELING)",
      "translationKey": "COUNTRY.AUS",
      "disabled": true,
      "phone": "61",
      "ISOCode": "AUS",
      "flag": "./assets/media/svg/custom-flags/au.svg",
      "code": "AU",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "AUD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ISLA DE NAVIDAD",
      "nameEn": "COCOS (KEELING) ISLANDS",
      "config": {
        "currency": "AUD",
        "language": "es",
        "timeZone": "Australia/Sydney"
      }
    },
    {
      "id": "040",
      "name": "AUSTRIA",
      "translationKey": "COUNTRY.AUT",
      "disabled": true,
      "phone": "43",
      "ISOCode": "AUT",
      "flag": "./assets/media/svg/custom-flags/at.svg",
      "code": "AT",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "AUSTRIA",
      "nameEn": "AUSTRIA",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Vienna"
      }
    },
    {
      "id": "031",
      "name": "AZERBAYÁN",
      "translationKey": "COUNTRY.AZE",
      "disabled": true,
      "phone": "994",
      "ISOCode": "AZE",
      "flag": "./assets/media/svg/custom-flags/az.svg",
      "code": "AZ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "AZN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "AZERBAIJAN",
      "nameEn": "AZERBAIJAN",
      "config": {
        "currency": "AZN",
        "language": "es",
        "timeZone": "Asia/Baku"
      }
    },
    {
      "id": "044",
      "name": "BAHAMAS",
      "translationKey": "COUNTRY.BHS",
      "disabled": true,
      "phone": "1242",
      "ISOCode": "BHS",
      "flag": "./assets/media/svg/custom-flags/bs.svg",
      "code": "BS",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BSD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BAHAMAS",
      "nameEn": "BAHAMAS",
      "config": {
        "currency": "BSD",
        "language": "es",
        "timeZone": "America/Nassau"
      }
    },
    {
      "id": "048",
      "name": "BAHREIN",
      "translationKey": "COUNTRY.BHR",
      "disabled": true,
      "phone": "973",
      "ISOCode": "BHR",
      "flag": "./assets/media/svg/custom-flags/bh.svg",
      "code": "BH",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BHD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BAHRAIN",
      "nameEn": "BAHRAIN",
      "config": {
        "currency": "BHD",
        "language": "es",
        "timeZone": "Asia/Bahrain"
      }
    },
    {
      "id": "050",
      "name": "BANGLADESH",
      "translationKey": "COUNTRY.BGD",
      "disabled": true,
      "phone": "880",
      "ISOCode": "BGD",
      "flag": "./assets/media/svg/custom-flags/bd.svg",
      "code": "BD",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BDT",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BANGLADESH",
      "nameEn": "BANGLADESH",
      "config": {
        "currency": "BDT",
        "language": "es",
        "timeZone": "Asia/Dhaka"
      }
    },
    {
      "id": "052",
      "name": "BARBADOS",
      "translationKey": "COUNTRY.BRB",
      "disabled": true,
      "phone": "1246",
      "ISOCode": "BRB",
      "flag": "./assets/media/svg/custom-flags/bb.svg",
      "code": "BB",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BBD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BARBADOS",
      "nameEn": "BARBADOS",
      "config": {
        "currency": "BBD",
        "language": "es",
        "timeZone": "America/Barbados"
      }
    },
    {
      "id": "112",
      "name": "BIELORRUSIA",
      "translationKey": "COUNTRY.BLR",
      "disabled": true,
      "phone": "375",
      "ISOCode": "BLR",
      "flag": "./assets/media/svg/custom-flags/by.svg",
      "code": "BY",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BYN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BELARUS",
      "nameEn": "BELARUS",
      "config": {
        "currency": "BYR",
        "language": "es",
        "timeZone": "Europe/Minsk"
      }
    },
    {
      "id": "056",
      "name": "BÉLGICA",
      "translationKey": "COUNTRY.BEL",
      "disabled": true,
      "phone": "32",
      "ISOCode": "BEL",
      "flag": "./assets/media/svg/custom-flags/be.svg",
      "code": "BE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BELGIUM",
      "nameEn": "BELGIUM",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Brussels"
      }
    },
    {
      "id": "084",
      "name": "BELICE",
      "translationKey": "COUNTRY.BLZ",
      "disabled": true,
      "phone": "501",
      "ISOCode": "BLZ",
      "flag": "./assets/media/svg/custom-flags/bz.svg",
      "code": "BZ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BZD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BELIZE",
      "nameEn": "BELIZE",
      "config": {
        "currency": "BZD",
        "language": "es",
        "timeZone": "America/Belize"
      }
    },
    {
      "id": "204",
      "name": "BENÍN",
      "translationKey": "COUNTRY.BEN",
      "disabled": true,
      "phone": "229",
      "ISOCode": "BEN",
      "flag": "./assets/media/svg/custom-flags/bj.svg",
      "code": "BJ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XOF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BENIN",
      "nameEn": "BENIN",
      "config": {
        "currency": "XOF",
        "language": "es",
        "timeZone": "Porto-Novo"
      }
    },
    {
      "id": "060",
      "name": "ISLAS BERMUDAS",
      "translationKey": "COUNTRY.BMU",
      "disabled": true,
      "phone": "1441",
      "ISOCode": "BMU",
      "flag": "./assets/media/svg/custom-flags/bm.svg",
      "code": "BM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BMD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BERMUDA",
      "nameEn": "BERMUDA ISLANDS",
      "config": {
        "currency": "BMD",
        "language": "es",
        "timeZone": "Atlantic/Bermuda"
      }
    },
    {
      "id": "064",
      "name": "BHUTÁN",
      "translationKey": "COUNTRY.BTN",
      "disabled": true,
      "phone": "975",
      "ISOCode": "BTN",
      "flag": "./assets/media/svg/custom-flags/bt.svg",
      "code": "BT",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BTN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BHUTAN",
      "nameEn": "BHUTAN",
      "config": {
        "currency": "BTN",
        "language": "es",
        "timeZone": "Asia/Thimphu"
      }
    },
    {
      "id": "068",
      "name": "BOLIVIA",
      "translationKey": "COUNTRY.BOL",
      "disabled": false,
      "phone": "591",
      "ISOCode": "BOL",
      "flag": "./assets/media/svg/custom-flags/bo.svg",
      "code": "BO",
      "config": {
        "currency": "BOB",
        "language": "es",
        "timeZone": "America/La_Paz"
      },
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BOB",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BOLIVIA",
      "nameEn": "BOLIVIA"
    },
    {
      "id": "070",
      "name": "BOSNIA Y HERZEGOVINA",
      "translationKey": "COUNTRY.BIH",
      "disabled": true,
      "phone": "387",
      "ISOCode": "BIH",
      "flag": "./assets/media/svg/custom-flags/ba.svg",
      "code": "BA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BAM",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BOSNIA AND HERZEGOVINA",
      "nameEn": "BOSNIA AND HERZEGOVINA",
      "config": {
        "currency": "BAM",
        "language": "es",
        "timeZone": "Europe/Sarajevo"
      }
    },
    {
      "id": "072",
      "name": "BOTSUANA",
      "translationKey": "COUNTRY.BWA",
      "disabled": true,
      "phone": "267",
      "ISOCode": "BWA",
      "flag": "./assets/media/svg/custom-flags/bw.svg",
      "code": "BW",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BWP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BOTSWANA",
      "nameEn": "BOTSWANA",
      "config": {
        "currency": "BWP",
        "language": "es",
        "timeZone": "Africa/Gaborone"
      }
    },
    {
      "id": "076",
      "name": "BRASIL",
      "translationKey": "COUNTRY.BRA",
      "disabled": false,
      "phone": "55",
      "ISOCode": "BRA",
      "flag": "./assets/media/svg/custom-flags/br.svg",
      "code": "BR",
      "config": {
        "currency": "BRL",
        "language": "es",
        "timeZone": "America/Sao_Paulo"
      },
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BRL",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BRAZIL",
      "nameEn": "BRAZIL"
    },
    {
      "id": "096",
      "name": "BRUNÉI",
      "translationKey": "COUNTRY.BRN",
      "disabled": true,
      "phone": "673",
      "ISOCode": "BRN",
      "flag": "./assets/media/svg/custom-flags/bn.svg",
      "code": "BN",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BND",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BRUNEI DARUSSALAM",
      "nameEn": "BRUNEI",
      "config": {
        "currency": "BND",
        "language": "es",
        "timeZone": "Asia/Brunei"
      }
    },
    {
      "id": "100",
      "name": "BULGARIA",
      "translationKey": "COUNTRY.BGR",
      "disabled": true,
      "phone": "359",
      "ISOCode": "BGR",
      "flag": "./assets/media/svg/custom-flags/bg.svg",
      "code": "BG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BGN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BULGARIA",
      "nameEn": "BULGARIA",
      "config": {
        "currency": "BGN",
        "language": "es",
        "timeZone": "Europe/Sofia"
      }
    },
    {
      "id": "854",
      "name": "BURKINA FASO",
      "translationKey": "COUNTRY.BFA",
      "disabled": true,
      "phone": "226",
      "ISOCode": "BFA",
      "flag": "./assets/media/svg/custom-flags/bf.svg",
      "code": "BF",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XOF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BURKINA FASO",
      "nameEn": "BURKINA FASO",
      "config": {
        "currency": "XOF",
        "language": "es",
        "timeZone": "Africa/Ouagadougou"
      }
    },
    {
      "id": "108",
      "name": "BURUNDI",
      "translationKey": "COUNTRY.BDI",
      "disabled": true,
      "phone": "257",
      "ISOCode": "BDI",
      "flag": "./assets/media/svg/custom-flags/bi.svg",
      "code": "BI",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "BIF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "BURUNDI",
      "nameEn": "BURUNDI",
      "config": {
        "currency": "BIF",
        "language": "es",
        "timeZone": "Africa/Bujumbura"
      }
    },
    {
      "id": "116",
      "name": "CAMBOYA",
      "translationKey": "COUNTRY.KHM",
      "disabled": true,
      "phone": "855",
      "ISOCode": "KHM",
      "flag": "./assets/media/svg/custom-flags/kh.svg",
      "code": "KH",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "KHR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CAMBODIA",
      "nameEn": "CAMBODIA",
      "config": {
        "currency": "KHR",
        "language": "es",
        "timeZone": "Asia/Phnom_Penh"
      }
    },
    {
      "id": "120",
      "name": "CAMERÚN",
      "translationKey": "COUNTRY.CMR",
      "disabled": true,
      "phone": "237",
      "ISOCode": "CMR",
      "flag": "./assets/media/svg/custom-flags/cm.svg",
      "code": "CM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XAF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CAMEROON",
      "nameEn": "CAMEROON",
      "config": {
        "currency": "XAF",
        "language": "es",
        "timeZone": "Africa/Douala"
      }
    },
    {
      "id": "124",
      "name": "CANADÁ",
      "translationKey": "COUNTRY.CAN",
      "disabled": false,
      "phone": "1",
      "ISOCode": "CAN",
      "flag": "./assets/media/svg/custom-flags/ca.svg",
      "code": "CA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "CAD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CANADA",
      "nameEn": "CANADÁ",
      "config": {
        "currency": "CAD",
        "language": "es",
        "timeZone": "America/Toronto"
      }
    },
    {
      "id": "132",
      "name": "CABO VERDE",
      "translationKey": "COUNTRY.CPV",
      "disabled": true,
      "phone": "238",
      "ISOCode": "CPV",
      "flag": "./assets/media/svg/custom-flags/cv.svg",
      "code": "CV",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "CVE",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CAPE VERDE",
      "nameEn": "CAPE VERDE",
      "config": {
        "currency": "CVE",
        "language": "es",
        "timeZone": "Atlantic/Cape_Verde"
      }
    },
    {
      "id": "136",
      "name": "ISLAS CAIMÁN",
      "translationKey": "COUNTRY.CYM",
      "disabled": true,
      "phone": "1345",
      "ISOCode": "CYM",
      "flag": "./assets/media/svg/custom-flags/ky.svg",
      "code": "KY",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "KYD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CAYMAN ISLANDS",
      "nameEn": "CAYMAN ISLANDS",
      "config": {
        "currency": "KYD",
        "language": "es",
        "timeZone": "America/Cayman"
      }
    },
    {
      "id": "140",
      "name": "REPÚBLICA CENTROAFRICANA",
      "translationKey": "COUNTRY.CAF",
      "disabled": true,
      "phone": "236",
      "ISOCode": "CAF",
      "flag": "./assets/media/svg/custom-flags/cf.svg",
      "code": "CF",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XAF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CENTRAL AFRICAN REPUBLIC",
      "nameEn": "CENTRAL AFRICAN REPUBLIC",
      "config": {
        "currency": "XAF",
        "language": "es",
        "timeZone": "Africa/Bangui"
      }
    },
    {
      "id": "148",
      "name": "CHAD",
      "translationKey": "COUNTRY.TCD",
      "disabled": true,
      "phone": "235",
      "ISOCode": "TCD",
      "flag": "./assets/media/svg/custom-flags/td.svg",
      "code": "TD",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XAF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CHAD",
      "nameEn": "CHAD",
      "config": {
        "currency": "XAF",
        "language": "es",
        "timeZone": "Africa/Ndjamena"
      }
    },
    {
      "id": "152",
      "name": "CHILE",
      "translationKey": "COUNTRY.CHL",
      "disabled": false,
      "phone": "56",
      "ISOCode": "CHL",
      "flag": "./assets/media/svg/custom-flags/cl.svg",
      "code": "CL",
      "config": {
        "currency": "CLP",
        "language": "es",
        "timeZone": "America/Santiago"
      },
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": ["CLF"]
        },
        {
          "code": "CLP",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "CLF",
          "excludeIfSecondaryCurrency": ["USD"]
        }
      ],
      "nameBack": "CHILE",
      "nameEn": "CHILE"
    },
    {
      "id": "156",
      "name": "CHINA",
      "translationKey": "COUNTRY.CHN",
      "disabled": true,
      "phone": "86",
      "ISOCode": "CHN",
      "flag": "./assets/media/svg/custom-flags/cn.svg",
      "code": "CN",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "CNY",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CHINA",
      "nameEn": "CHINA",
      "config": {
        "currency": "CNY",
        "language": "es",
        "timeZone": "Asia/Shanghai"
      }
    },
    {
      "id": "170",
      "name": "COLOMBIA",
      "translationKey": "COUNTRY.COL",
      "disabled": false,
      "phone": "57",
      "ISOCode": "COL",
      "flag": "./assets/media/svg/custom-flags/co.svg",
      "code": "CO",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "COP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "config": {
        "currency": "COP",
        "language": "es",
        "timeZone": "America/Bogota"
      },
      "nameBack": "COLOMBIA",
      "nameEn": "COLOMBIA"
    },
    {
      "id": "174",
      "name": "COMORAS",
      "translationKey": "COUNTRY.COM",
      "disabled": true,
      "phone": "269",
      "ISOCode": "COM",
      "flag": "./assets/media/svg/custom-flags/km.svg",
      "code": "KM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "KMF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "COMOROS",
      "nameEn": "COMOROS",
      "config": {
        "currency": "KMF",
        "language": "es",
        "timeZone": "Indian/Comoro"
      }
    },
    {
      "id": "178",
      "name": "CONGO",
      "translationKey": "COUNTRY.COG",
      "disabled": true,
      "phone": "242",
      "ISOCode": "COG",
      "flag": "./assets/media/svg/custom-flags/cg.svg",
      "code": "CG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XAF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CONGO",
      "nameEn": "CONGO",
      "config": {
        "currency": "XAF",
        "language": "es",
        "timeZone": "Africa/Brazzaville"
      }
    },
    {
      "id": "180",
      "name": "REPÚBLICA DEMOCRÁTICA DEL CONGO",
      "translationKey": "COUNTRY.COD",
      "disabled": true,
      "phone": "242",
      "ISOCode": "COD",
      "flag": "./assets/media/svg/custom-flags/cd.svg",
      "code": "CD",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "CDF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
      "nameEn": "CONGO",
      "config": {
        "currency": "CDF",
        "language": "es",
        "timeZone": "Africa/Kinshasa"
      }
    },
    {
      "id": "184",
      "name": "ISLAS COOK",
      "translationKey": "COUNTRY.COK",
      "disabled": true,
      "phone": "682",
      "ISOCode": "COK",
      "flag": "./assets/media/svg/custom-flags/ck.svg",
      "code": "CK",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "NZD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "COOK ISLANDS",
      "nameEn": "COOK ISLANDS",
      "config": {
        "currency": "NZD",
        "language": "es",
        "timeZone": "Pacific/Rarotonga"
      }
    },
    {
      "id": "188",
      "name": "COSTA RICA",
      "translationKey": "COUNTRY.CRI",
      "disabled": false,
      "phone": "506",
      "ISOCode": "CRI",
      "flag": "./assets/media/svg/custom-flags/cr.svg",
      "code": "CR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "CRC",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "COSTA RICA",
      "nameEn": "COSTA RICA",
      "config": {
        "currency": "CRC",
        "language": "es",
        "timeZone": "America/Costa_Rica"
      }
    },
    {
      "id": "384",
      "name": "COSTA DE MARFIL",
      "translationKey": "COUNTRY.CIV",
      "disabled": true,
      "phone": "225",
      "ISOCode": "CIV",
      "flag": "./assets/media/svg/custom-flags/ci.svg",
      "code": "CI",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XOF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "COTE D'IVOIRE",
      "nameEn": "IVORY COAST",
      "config": {
        "currency": "XOF",
        "language": "es",
        "timeZone": "Africa/Abidjan"
      }
    },
    {
      "id": "191",
      "name": "CROACIA",
      "translationKey": "COUNTRY.HRV",
      "disabled": true,
      "phone": "385",
      "ISOCode": "HRV",
      "flag": "./assets/media/svg/custom-flags/hr.svg",
      "code": "HR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "HRK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CROATIA",
      "nameEn": "CROATIA",
      "config": {
        "currency": "HRK",
        "language": "es",
        "timeZone": "Europe/Zagreb"
      }
    },
    {
      "id": "192",
      "name": "CUBA",
      "translationKey": "COUNTRY.CUB",
      "disabled": true,
      "phone": "53",
      "ISOCode": "CUB",
      "flag": "./assets/media/svg/custom-flags/cu.svg",
      "code": "CU",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "CUC",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CUBA",
      "nameEn": "CUBA",
      "config": {
        "currency": "CUP",
        "language": "es",
        "timeZone": "America/Havana"
      }
    },
    {
      "id": "196",
      "name": "CHIPRE",
      "translationKey": "COUNTRY.CYP",
      "disabled": true,
      "phone": "357",
      "ISOCode": "CYP",
      "flag": "./assets/media/svg/custom-flags/cy.svg",
      "code": "CY",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CYPRUS",
      "nameEn": "CYPRUS",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Asia/Famagusta"
      }
    },
    {
      "id": "203",
      "name": "REPÚBLICA CHECA",
      "translationKey": "COUNTRY.CZE",
      "disabled": true,
      "phone": "420",
      "ISOCode": "CZE",
      "flag": "./assets/media/svg/custom-flags/cz.svg",
      "code": "CZ",
      "currencies": [],
      "nameBack": "CZECH REPUBLIC",
      "nameEn": "CZECH REPUBLIC",
      "config": {
        "currency": "CZK",
        "language": "es",
        "timeZone": "Europe/Prague"
      }
    },
    {
      "id": "208",
      "name": "DINAMARCA",
      "translationKey": "COUNTRY.DNK",
      "disabled": true,
      "phone": "45",
      "ISOCode": "DNK",
      "flag": "./assets/media/svg/custom-flags/dk.svg",
      "code": "DK",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "DKK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "DENMARK",
      "nameEn": "DENMARK",
      "config": {
        "currency": "DKK",
        "language": "es",
        "timeZone": "Europe/Copenhagen"
      }
    },
    {
      "id": "262",
      "name": "YIBUTI",
      "translationKey": "COUNTRY.DJI",
      "disabled": true,
      "phone": "253",
      "ISOCode": "DJI",
      "flag": "./assets/media/svg/custom-flags/dj.svg",
      "code": "DJ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "DJF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "DJIBOUTI",
      "nameEn": "DJIBOUTI",
      "config": {
        "currency": "DJF",
        "language": "es",
        "timeZone": "Africa/Djibouti"
      }
    },
    {
      "id": "212",
      "name": "DOMINICA",
      "translationKey": "COUNTRY.DMA",
      "disabled": true,
      "phone": "1767",
      "ISOCode": "DMA",
      "flag": "./assets/media/svg/custom-flags/dm.svg",
      "code": "DM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XCD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "DOMINICA",
      "nameEn": "DOMINICA",
      "config": {
        "currency": "XCD",
        "language": "es",
        "timeZone": "America/Dominica"
      }
    },
    {
      "id": "214",
      "name": "REPÚBLICA DOMINICANA",
      "translationKey": "COUNTRY.DOM",
      "disabled": false,
      "phone": "1",
      "ISOCode": "DOM",
      "flag": "./assets/media/svg/custom-flags/do.svg",
      "code": "DO",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "DOP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "DOMINICAN REPUBLIC",
      "nameEn": "DOMINICAN REPUBLIC",
      "config": {
        "currency": "DOP",
        "language": "es",
        "timeZone": "America/Santo_Domingo"
      }
    },
    {
      "id": "218",
      "name": "ECUADOR",
      "translationKey": "COUNTRY.ECU",
      "disabled": false,
      "phone": "593",
      "ISOCode": "ECU",
      "flag": "./assets/media/svg/custom-flags/ec.svg",
      "code": "EC",
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "America/Guayaquil"
      },
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ECUADOR",
      "nameEn": "ECUADOR"
    },
    {
      "id": "818",
      "name": "EGIPTO",
      "translationKey": "COUNTRY.EGY",
      "disabled": true,
      "phone": "20",
      "ISOCode": "EGY",
      "flag": "./assets/media/svg/custom-flags/eg.svg",
      "code": "EG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EGP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "EGYPT",
      "nameEn": "EGYPT",
      "config": {
        "currency": "EGP",
        "language": "es",
        "timeZone": "Africa/Cairo"
      }
    },
    {
      "id": "222",
      "name": "EL SALVADOR",
      "translationKey": "COUNTRY.SLV",
      "disabled": false,
      "phone": "503",
      "ISOCode": "SLV",
      "flag": "./assets/media/svg/custom-flags/sv.svg",
      "code": "SV",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SVC",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "EL SALVADOR",
      "nameEn": "EL SALVADOR",
      "config": {
        "currency": "SVC",
        "language": "es",
        "timeZone": "America/El_Salvador"
      }
    },
    {
      "id": "226",
      "name": "GUINEA ECUATORIAL",
      "translationKey": "COUNTRY.GNQ",
      "disabled": true,
      "phone": "240",
      "ISOCode": "GNQ",
      "flag": "./assets/media/svg/custom-flags/gq.svg",
      "code": "GQ",
      "currencies": [],
      "nameBack": "EQUATORIAL GUINEA",
      "nameEn": "EQUATORIAL GUINEA",
      "config": {
        "currency": "XAF",
        "language": "es",
        "timeZone": "Africa/Malabo"
      }
    },
    {
      "id": "232",
      "name": "ERITREA",
      "translationKey": "COUNTRY.ERI",
      "disabled": true,
      "phone": "291",
      "ISOCode": "ERI",
      "flag": "./assets/media/svg/custom-flags/er.svg",
      "code": "ER",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "ERN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ERITREA",
      "nameEn": "ERITREA",
      "config": {
        "currency": "ERN",
        "language": "es",
        "timeZone": "Africa/Asmara"
      }
    },
    {
      "id": "233",
      "name": "ESTONIA",
      "translationKey": "COUNTRY.EST",
      "disabled": true,
      "phone": "372",
      "ISOCode": "EST",
      "flag": "./assets/media/svg/custom-flags/ee.svg",
      "code": "EE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ESTONIA",
      "nameEn": "ESTONIA",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Tallinn"
      }
    },
    {
      "id": "231",
      "name": "ETIOPÍA",
      "translationKey": "COUNTRY.ETH",
      "disabled": true,
      "phone": "251",
      "ISOCode": "ETH",
      "flag": "./assets/media/svg/custom-flags/et.svg",
      "code": "ET",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "ETB",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ETHIOPIA",
      "nameEn": "ETHIOPIA",
      "config": {
        "currency": "ETB",
        "language": "es",
        "timeZone": "Africa/Addis_Ababa"
      }
    },
    {
      "id": "238",
      "name": "ISLAS MALVINAS",
      "translationKey": "COUNTRY.FLK",
      "disabled": true,
      "phone": "500",
      "ISOCode": "FLK",
      "flag": "./assets/media/svg/custom-flags/fk.svg",
      "code": "FK",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "FKP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "FALKLAND ISLANDS (MALVINAS)",
      "nameEn": "FALKLAND ISLANDS (MALVINAS)",
      "config": {
        "currency": "FKP",
        "language": "es",
        "timeZone": "Atlantic/Stanley"
      }
    },
    {
      "id": "234",
      "name": "ISLAS FEROE",
      "translationKey": "COUNTRY.FRO",
      "disabled": true,
      "phone": "298",
      "ISOCode": "FRO",
      "flag": "./assets/media/svg/custom-flags/fo.svg",
      "code": "FO",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "DKK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "FAROE ISLANDS",
      "nameEn": "FAROE ISLANDS",
      "config": {
        "currency": "DKK",
        "language": "es",
        "timeZone": "Atlantic/Faroe"
      }
    },
    {
      "id": "242",
      "name": "FIYI",
      "translationKey": "COUNTRY.FJI",
      "disabled": true,
      "phone": "679",
      "ISOCode": "FJI",
      "flag": "./assets/media/svg/custom-flags/fj.svg",
      "code": "FJ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "FJD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "FIJI",
      "nameEn": "FIJI",
      "config": {
        "currency": "FJD",
        "language": "es",
        "timeZone": "Pacific/Fiji"
      }
    },
    {
      "id": "246",
      "name": "FINLANDIA",
      "translationKey": "COUNTRY.FIN",
      "disabled": true,
      "phone": "358",
      "ISOCode": "FIN",
      "flag": "./assets/media/svg/custom-flags/fi.svg",
      "code": "FI",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "FINLAND",
      "nameEn": "FINLAND",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Helsinki"
      }
    },
    {
      "id": "250",
      "name": "FRANCIA",
      "translationKey": "COUNTRY.FRA",
      "disabled": true,
      "phone": "33",
      "ISOCode": "FRA",
      "flag": "./assets/media/svg/custom-flags/fr.svg",
      "code": "FR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "FRANCE",
      "nameEn": "FRANCE",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Paris"
      }
    },
    {
      "id": "254",
      "name": "FRENCH GUIANA",
      "translationKey": "COUNTRY.GUF",
      "disabled": true,
      "phone": "594",
      "ISOCode": "GUF",
      "flag": "./assets/media/svg/custom-flags/gf.svg",
      "code": "GF",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "America/Cayenne"
      }
    },
    {
      "id": "258",
      "name": "POLINESIA FRANCESA",
      "translationKey": "COUNTRY.PYF",
      "disabled": true,
      "phone": "689",
      "ISOCode": "PYF",
      "flag": "./assets/media/svg/custom-flags/pf.svg",
      "code": "PF",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XPF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "FRENCH POLYNESIA",
      "nameEn": "FRENCH POLYNESIA",
      "config": {
        "currency": "XPF",
        "language": "es",
        "timeZone": "Pacific/Gambier"
      }
    },
    {
      "id": "266",
      "name": "GABÓN",
      "translationKey": "COUNTRY.GAB",
      "disabled": true,
      "phone": "241",
      "ISOCode": "GAB",
      "flag": "./assets/media/svg/custom-flags/ga.svg",
      "code": "GA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XAF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GABON",
      "nameEn": "GABON",
      "config": {
        "currency": "XAF",
        "language": "es",
        "timeZone": "Africa/Libreville"
      }
    },
    {
      "id": "270",
      "name": "GAMBIA",
      "translationKey": "COUNTRY.GMB",
      "disabled": true,
      "phone": "220",
      "ISOCode": "GMB",
      "flag": "./assets/media/svg/custom-flags/gm.svg",
      "code": "GM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "GMD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GAMBIA",
      "nameEn": "GAMBIA",
      "config": {
        "currency": "GMD",
        "language": "es",
        "timeZone": "Africa/Banjul"
      }
    },
    {
      "id": "268",
      "name": "GEORGIA",
      "translationKey": "COUNTRY.GEO",
      "disabled": true,
      "phone": "995",
      "ISOCode": "GEO",
      "flag": "./assets/media/svg/custom-flags/ge.svg",
      "code": "GE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "GEL",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GEORGIA",
      "nameEn": "GEORGIA",
      "config": {
        "currency": "GEL",
        "language": "es",
        "timeZone": "Asia/Tbilisi"
      }
    },
    {
      "id": "276",
      "name": "ALEMANIA",
      "translationKey": "COUNTRY.DEU",
      "disabled": true,
      "phone": "49",
      "ISOCode": "DEU",
      "flag": "./assets/media/svg/custom-flags/de.svg",
      "code": "DE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GERMANY",
      "nameEn": "GERMANY",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Berlin"
      }
    },
    {
      "id": "288",
      "name": "GHANA",
      "translationKey": "COUNTRY.GHA",
      "disabled": true,
      "phone": "233",
      "ISOCode": "GHA",
      "flag": "./assets/media/svg/custom-flags/gh.svg",
      "code": "GH",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "GHS",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GHANA",
      "nameEn": "GHANA",
      "config": {
        "currency": "GHS",
        "language": "es",
        "timeZone": "Africa/Accra"
      }
    },
    {
      "id": "292",
      "name": "GIBRALTAR",
      "translationKey": "COUNTRY.GIB",
      "disabled": true,
      "phone": "350",
      "ISOCode": "GIB",
      "flag": "./assets/media/svg/custom-flags/gi.svg",
      "code": "GI",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "GIP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GIBRALTAR",
      "nameEn": "GIBRALTAR",
      "config": {
        "currency": "GIP",
        "language": "es",
        "timeZone": "Europe/Gibraltar"
      }
    },
    {
      "id": "300",
      "name": "GRECIA",
      "translationKey": "COUNTRY.GRC",
      "disabled": true,
      "phone": "30",
      "ISOCode": "GRC",
      "flag": "./assets/media/svg/custom-flags/gr.svg",
      "code": "GR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GREECE",
      "nameEn": "GREECE",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Athens"
      }
    },
    {
      "id": "304",
      "name": "GROENLANDIA",
      "translationKey": "COUNTRY.GRL",
      "disabled": true,
      "phone": "299",
      "ISOCode": "GRL",
      "flag": "./assets/media/svg/custom-flags/gl.svg",
      "code": "GL",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "DKK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GREENLAND",
      "nameEn": "GREENLAND",
      "config": {
        "currency": "DKK",
        "language": "es",
        "timeZone": "America/Nuuk"
      }
    },
    {
      "id": "308",
      "name": "GRANADA",
      "translationKey": "COUNTRY.GRD",
      "disabled": true,
      "phone": "1473",
      "ISOCode": "GRD",
      "flag": "./assets/media/svg/custom-flags/gd.svg",
      "code": "GD",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XCD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GRENADA",
      "nameEn": "GRENADA",
      "config": {
        "currency": "XCD",
        "language": "es",
        "timeZone": "America/Grenada"
      }
    },
    {
      "id": "312",
      "name": "SAN BARTOLOMÉ",
      "translationKey": "COUNTRY.GLP",
      "disabled": true,
      "phone": "590",
      "ISOCode": "GLP",
      "flag": "./assets/media/svg/custom-flags/gp.svg",
      "code": "GP",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GUADELOUPE",
      "nameEn": "SAINT BARTHÉLEMY",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "America/Guadeloupe"
      }
    },
    {
      "id": "316",
      "name": "GUAM",
      "translationKey": "COUNTRY.GUM",
      "disabled": true,
      "phone": "1671",
      "ISOCode": "GUM",
      "flag": "./assets/media/svg/custom-flags/gu.svg",
      "code": "GU",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GUAM",
      "nameEn": "GUAM",
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "Pacific/Guam"
      }
    },
    {
      "id": "320",
      "name": "GUATEMALA",
      "translationKey": "COUNTRY.GTM",
      "disabled": false,
      "phone": "502",
      "ISOCode": "GTM",
      "flag": "./assets/media/svg/custom-flags/gt.svg",
      "code": "GT",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "GTQ",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GUATEMALA",
      "nameEn": "GUATEMALA",
      "config": {
        "currency": "GTQ",
        "language": "es",
        "timeZone": "America/Guatemala"
      }
    },
    {
      "id": "324",
      "name": "GUINEA",
      "translationKey": "COUNTRY.GIN",
      "disabled": true,
      "phone": "224",
      "ISOCode": "GIN",
      "flag": "./assets/media/svg/custom-flags/gn.svg",
      "code": "GN",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "GNF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GUINEA",
      "nameEn": "GUINEA",
      "config": {
        "currency": "GNF",
        "language": "es",
        "timeZone": "Africa/Conakry"
      }
    },
    {
      "id": "624",
      "name": "GUINEA-BISSAU",
      "translationKey": "COUNTRY.GNB",
      "disabled": true,
      "phone": "245",
      "ISOCode": "GNB",
      "flag": "./assets/media/svg/custom-flags/gw.svg",
      "code": "GW",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XAF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GUINEA-BISSAU",
      "nameEn": "GUINEA-BISSAU",
      "config": {
        "currency": "XOF",
        "language": "es",
        "timeZone": "Africa/Bissau"
      }
    },
    {
      "id": "328",
      "name": "GUYANA",
      "translationKey": "COUNTRY.GUY",
      "disabled": true,
      "phone": "592",
      "ISOCode": "GUY",
      "flag": "./assets/media/svg/custom-flags/gy.svg",
      "code": "GY",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "GYD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "GUYANA",
      "nameEn": "GUYANA",
      "config": {
        "currency": "GYD",
        "language": "es",
        "timeZone": "America/Guyana"
      }
    },
    {
      "id": "332",
      "name": "HAITÍ",
      "translationKey": "COUNTRY.HTI",
      "disabled": true,
      "phone": "509",
      "ISOCode": "HTI",
      "flag": "./assets/media/svg/custom-flags/ht.svg",
      "code": "HT",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "HTG",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "HAITI",
      "nameEn": "HAITI",
      "config": {
        "currency": "HTG",
        "language": "es",
        "timeZone": "au-Prince"
      }
    },
    {
      "id": "336",
      "name": "CIUDAD DEL VATICANO",
      "translationKey": "COUNTRY.VAT",
      "disabled": true,
      "phone": "39",
      "ISOCode": "VAT",
      "flag": "./assets/media/svg/custom-flags/va.svg",
      "code": "VA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CIUDAD DEL VATICANO",
      "nameEn": "ITALY",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Vatican"
      }
    },
    {
      "id": "340",
      "name": "HONDURAS",
      "translationKey": "COUNTRY.HND",
      "disabled": false,
      "phone": "504",
      "ISOCode": "HND",
      "flag": "./assets/media/svg/custom-flags/hn.svg",
      "code": "HN",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "HNL",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "HONDURAS",
      "nameEn": "HONDURAS",
      "config": {
        "currency": "HNL",
        "language": "es",
        "timeZone": "America/Tegucigalpa"
      }
    },
    {
      "id": "344",
      "name": "HONG KONG",
      "translationKey": "COUNTRY.HKG",
      "disabled": true,
      "phone": "852",
      "ISOCode": "HKG",
      "flag": "./assets/media/svg/custom-flags/hk.svg",
      "code": "HK",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "HKD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "HONG KONG",
      "nameEn": "HONG KONG",
      "config": {
        "currency": "HKD",
        "language": "es",
        "timeZone": "Asia/Hong_Kong"
      }
    },
    {
      "id": "348",
      "name": "HUNGRÍA",
      "translationKey": "COUNTRY.HUN",
      "disabled": true,
      "phone": "36",
      "ISOCode": "HUN",
      "flag": "./assets/media/svg/custom-flags/hu.svg",
      "code": "HU",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "HUF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "HUNGARY",
      "nameEn": "HUNGARY",
      "config": {
        "currency": "HUF",
        "language": "es",
        "timeZone": "Europe/Budapest"
      }
    },
    {
      "id": "352",
      "name": "ISLANDIA",
      "translationKey": "COUNTRY.ISL",
      "disabled": true,
      "phone": "354",
      "ISOCode": "ISL",
      "flag": "./assets/media/svg/custom-flags/is.svg",
      "code": "IS",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "ISK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ICELAND",
      "nameEn": "ICELAND",
      "config": {
        "currency": "ISK",
        "language": "es",
        "timeZone": "Atlantic/Reykjavik"
      }
    },
    {
      "id": "356",
      "name": "INDIA",
      "translationKey": "COUNTRY.IND",
      "disabled": true,
      "phone": "91",
      "ISOCode": "IND",
      "flag": "./assets/media/svg/custom-flags/in.svg",
      "code": "IN",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "INR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "INDIA",
      "nameEn": "INDIA",
      "config": {
        "currency": "INR",
        "language": "es",
        "timeZone": "Asia/Kolkata"
      }
    },
    {
      "id": "360",
      "name": "INDONESIA",
      "translationKey": "COUNTRY.IDN",
      "disabled": true,
      "phone": "62",
      "ISOCode": "IDN",
      "flag": "./assets/media/svg/custom-flags/id.svg",
      "code": "ID",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "IDR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "INDONESIA",
      "nameEn": "INDONESIA",
      "config": {
        "currency": "IDR",
        "language": "es",
        "timeZone": "Asia/Jakarta"
      }
    },
    {
      "id": "364",
      "name": "IRÁN",
      "translationKey": "COUNTRY.IRN",
      "disabled": true,
      "phone": "98",
      "ISOCode": "IRN",
      "flag": "./assets/media/svg/custom-flags/ir.svg",
      "code": "IR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "IRR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "IRAN, ISLAMIC REPUBLIC OF",
      "nameEn": "IRAN",
      "config": {
        "currency": "IRR",
        "language": "es",
        "timeZone": "Asia/Tehran"
      }
    },
    {
      "id": "368",
      "name": "IRAK",
      "translationKey": "COUNTRY.IRQ",
      "disabled": true,
      "phone": "964",
      "ISOCode": "IRQ",
      "flag": "./assets/media/svg/custom-flags/iq.svg",
      "code": "IQ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "IQD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "IRAQ",
      "nameEn": "IRAQ",
      "config": {
        "currency": "IQD",
        "language": "es",
        "timeZone": "Asia/Baghdad"
      }
    },
    {
      "id": "372",
      "name": "IRLANDA",
      "translationKey": "COUNTRY.IRL",
      "disabled": true,
      "phone": "353",
      "ISOCode": "IRL",
      "flag": "./assets/media/svg/custom-flags/ie.svg",
      "code": "IE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "IRELAND",
      "nameEn": "IRELAND",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Dublin"
      }
    },
    {
      "id": "376",
      "name": "ISRAEL",
      "translationKey": "COUNTRY.ISR",
      "disabled": true,
      "phone": "972",
      "ISOCode": "ISR",
      "flag": "./assets/media/svg/custom-flags/il.svg",
      "code": "IL",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "ILS",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ISRAEL",
      "nameEn": "ISRAEL",
      "config": {
        "currency": "ILS",
        "language": "es",
        "timeZone": "Asia/Jerusalem"
      }
    },
    {
      "id": "380",
      "name": "ITALIA",
      "translationKey": "COUNTRY.ITA",
      "disabled": true,
      "phone": "39",
      "ISOCode": "ITA",
      "flag": "./assets/media/svg/custom-flags/it.svg",
      "code": "IT",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "CIUDAD DEL VATICANO",
      "nameEn": "ITALY",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Rome"
      }
    },
    {
      "id": "388",
      "name": "JAMAICA",
      "translationKey": "COUNTRY.JAM",
      "disabled": true,
      "phone": "1876",
      "ISOCode": "JAM",
      "flag": "./assets/media/svg/custom-flags/jm.svg",
      "code": "JM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "JMD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "JAMAICA",
      "nameEn": "JAMAICA",
      "config": {
        "currency": "JMD",
        "language": "es",
        "timeZone": "America/Jamaica"
      }
    },
    {
      "id": "392",
      "name": "JAPÓN",
      "translationKey": "COUNTRY.JPN",
      "disabled": true,
      "phone": "81",
      "ISOCode": "JPN",
      "flag": "./assets/media/svg/custom-flags/jp.svg",
      "code": "JP",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "JPY",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "JAPAN",
      "nameEn": "JAPAN",
      "config": {
        "currency": "JPY",
        "language": "es",
        "timeZone": "Asia/Tokyo"
      }
    },
    {
      "id": "400",
      "name": "JORDANIA",
      "translationKey": "COUNTRY.JOR",
      "disabled": true,
      "phone": "962",
      "ISOCode": "JOR",
      "flag": "./assets/media/svg/custom-flags/jo.svg",
      "code": "JO",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "JOD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "JORDAN",
      "nameEn": "JORDAN",
      "config": {
        "currency": "JOD",
        "language": "es",
        "timeZone": "Asia/Amman"
      }
    },
    {
      "id": "398",
      "name": "RUSIA",
      "translationKey": "COUNTRY.KAZ",
      "disabled": true,
      "phone": "7",
      "ISOCode": "KAZ",
      "flag": "./assets/media/svg/custom-flags/kz.svg",
      "code": "KZ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "KZT",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "KAZAJISTÁN",
      "nameEn": "RUSSIA",
      "config": {
        "currency": "KZT",
        "language": "es",
        "timeZone": "Asia/Qyzylorda"
      }
    },
    {
      "id": "404",
      "name": "KENIA",
      "translationKey": "COUNTRY.KEN",
      "disabled": true,
      "phone": "254",
      "ISOCode": "KEN",
      "flag": "./assets/media/svg/custom-flags/ke.svg",
      "code": "KE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "KES",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "KENYA",
      "nameEn": "KENYA",
      "config": {
        "currency": "KES",
        "language": "es",
        "timeZone": "Africa/Nairobi"
      }
    },
    {
      "id": "296",
      "name": "KIRIBATI",
      "translationKey": "COUNTRY.KIR",
      "disabled": true,
      "phone": "686",
      "ISOCode": "KIR",
      "flag": "./assets/media/svg/custom-flags/ki.svg",
      "code": "KI",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "KID",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "KIRIBATI",
      "nameEn": "KIRIBATI",
      "config": {
        "currency": "AUD",
        "language": "es",
        "timeZone": "Pacific/Kanton"
      }
    },
    {
      "id": "408",
      "name": "COREA DEL NORTE",
      "translationKey": "COUNTRY.PRK",
      "disabled": true,
      "phone": "850",
      "ISOCode": "PRK",
      "flag": "./assets/media/svg/custom-flags/kp.svg",
      "code": "KP",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "KPW",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
      "nameEn": "NORTH KOREA",
      "config": {
        "currency": "KPW",
        "language": "es",
        "timeZone": "Asia/Pyongyang"
      }
    },
    {
      "id": "410",
      "name": "COREA DEL SUR",
      "translationKey": "COUNTRY.KOR",
      "disabled": true,
      "phone": "82",
      "ISOCode": "KOR",
      "flag": "./assets/media/svg/custom-flags/kr.svg",
      "code": "KR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "KRW",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "KOREA, REPUBLIC OF",
      "nameEn": "SOUTH KOREA",
      "config": {
        "currency": "KRW",
        "language": "es",
        "timeZone": "Asia/Seoul"
      }
    },
    {
      "id": "414",
      "name": "KUWAIT",
      "translationKey": "COUNTRY.KWT",
      "disabled": true,
      "phone": "965",
      "ISOCode": "KWT",
      "flag": "./assets/media/svg/custom-flags/kw.svg",
      "code": "KW",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "KWD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "KUWAIT",
      "nameEn": "KUWAIT",
      "config": {
        "currency": "KWD",
        "language": "es",
        "timeZone": "Asia/Kuwait"
      }
    },
    {
      "id": "417",
      "name": "KIRGIZSTÁN",
      "translationKey": "COUNTRY.KGZ",
      "disabled": true,
      "phone": "996",
      "ISOCode": "KGZ",
      "flag": "./assets/media/svg/custom-flags/kg.svg",
      "code": "KG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "KGS",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "KYRGYZSTAN",
      "nameEn": "KYRGYZSTAN",
      "config": {
        "currency": "KGS",
        "language": "es",
        "timeZone": "Asia/Bishkek"
      }
    },
    {
      "id": "418",
      "name": "LAOS",
      "translationKey": "COUNTRY.LAO",
      "disabled": true,
      "phone": "856",
      "ISOCode": "LAO",
      "flag": "./assets/media/svg/custom-flags/la.svg",
      "code": "LA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "LAK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
      "nameEn": "LAOS",
      "config": {
        "currency": "LAK",
        "language": "es",
        "timeZone": "Asia/Vientiane"
      }
    },
    {
      "id": "428",
      "name": "LETONIA",
      "translationKey": "COUNTRY.LVA",
      "disabled": true,
      "phone": "371",
      "ISOCode": "LVA",
      "flag": "./assets/media/svg/custom-flags/lv.svg",
      "code": "LV",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "LATVIA",
      "nameEn": "LATVIA",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Riga"
      }
    },
    {
      "id": "422",
      "name": "LÍBANO",
      "translationKey": "COUNTRY.LBN",
      "disabled": true,
      "phone": "961",
      "ISOCode": "LBN",
      "flag": "./assets/media/svg/custom-flags/lb.svg",
      "code": "LB",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "LBP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "LEBANON",
      "nameEn": "LEBANON",
      "config": {
        "currency": "LBP",
        "language": "es",
        "timeZone": "Asia/Beirut"
      }
    },
    {
      "id": "426",
      "name": "LESOTO",
      "translationKey": "COUNTRY.LSO",
      "disabled": true,
      "phone": "266",
      "ISOCode": "LSO",
      "flag": "./assets/media/svg/custom-flags/ls.svg",
      "code": "LS",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "LSL",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "LESOTHO",
      "nameEn": "LESOTHO",
      "config": {
        "currency": "LSL",
        "language": "es",
        "timeZone": "Africa/Maseru"
      }
    },
    {
      "id": "430",
      "name": "LIBERIA",
      "translationKey": "COUNTRY.LBR",
      "disabled": true,
      "phone": "231",
      "ISOCode": "LBR",
      "flag": "./assets/media/svg/custom-flags/lr.svg",
      "code": "LR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "LRD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "LIBERIA",
      "nameEn": "LIBERIA",
      "config": {
        "currency": "LRD",
        "language": "es",
        "timeZone": "Africa/Monrovia"
      }
    },
    {
      "id": "434",
      "name": "LIBIA",
      "translationKey": "COUNTRY.LBY",
      "disabled": true,
      "phone": "218",
      "ISOCode": "LBY",
      "flag": "./assets/media/svg/custom-flags/ly.svg",
      "code": "LY",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "LYD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "LIBYAN ARAB JAMAHIRIYA",
      "nameEn": "LIBYA",
      "config": {
        "currency": "LYD",
        "language": "es",
        "timeZone": "Africa/Tripoli"
      }
    },
    {
      "id": "438",
      "name": "LIECHTENSTEIN",
      "translationKey": "COUNTRY.LIE",
      "disabled": true,
      "phone": "423",
      "ISOCode": "LIE",
      "flag": "./assets/media/svg/custom-flags/li.svg",
      "code": "LI",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "CHF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "LIECHTENSTEIN",
      "nameEn": "LIECHTENSTEIN",
      "config": {
        "currency": "CHF",
        "language": "es",
        "timeZone": "Europe/Vaduz"
      }
    },
    {
      "id": "440",
      "name": "LITUANIA",
      "translationKey": "COUNTRY.LTU",
      "disabled": true,
      "phone": "370",
      "ISOCode": "LTU",
      "flag": "./assets/media/svg/custom-flags/lt.svg",
      "code": "LT",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "LTL",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "LITHUANIA",
      "nameEn": "LITHUANIA",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Vilnius"
      }
    },
    {
      "id": "442",
      "name": "LUXEMBURGO",
      "translationKey": "COUNTRY.LUX",
      "disabled": true,
      "phone": "352",
      "ISOCode": "LUX",
      "flag": "./assets/media/svg/custom-flags/lu.svg",
      "code": "LU",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "LUXEMBOURG",
      "nameEn": "LUXEMBOURG",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Luxembourg"
      }
    },
    {
      "id": "446",
      "name": "MACAO",
      "translationKey": "COUNTRY.MAC",
      "disabled": true,
      "phone": "853",
      "ISOCode": "MAC",
      "flag": "./assets/media/svg/custom-flags/mo.svg",
      "code": "MO",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MOP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MACAO",
      "nameEn": "MACAO",
      "config": {
        "currency": "MOP",
        "language": "es",
        "timeZone": "Asia/Macau"
      }
    },
    {
      "id": "807",
      "name": "MACEDÔNIA",
      "translationKey": "COUNTRY.MKD",
      "disabled": true,
      "phone": "389",
      "ISOCode": "MKD",
      "flag": "./assets/media/svg/custom-flags/mk.svg",
      "code": "MK",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MKD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
      "nameEn": "MACEDONIA",
      "config": {
        "currency": "MKD",
        "language": "es",
        "timeZone": "Europe/Skopje"
      }
    },
    {
      "id": "450",
      "name": "MADAGASCAR",
      "translationKey": "COUNTRY.MDG",
      "disabled": true,
      "phone": "261",
      "ISOCode": "MDG",
      "flag": "./assets/media/svg/custom-flags/mg.svg",
      "code": "MG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MGA",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MADAGASCAR",
      "nameEn": "MADAGASCAR",
      "config": {
        "currency": "MGA",
        "language": "es",
        "timeZone": "Indian/Antananarivo"
      }
    },
    {
      "id": "454",
      "name": "MALAWI",
      "translationKey": "COUNTRY.MWI",
      "disabled": true,
      "phone": "265",
      "ISOCode": "MWI",
      "flag": "./assets/media/svg/custom-flags/mw.svg",
      "code": "MW",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MWK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MALAWI",
      "nameEn": "MALAWI",
      "config": {
        "currency": "MWK",
        "language": "es",
        "timeZone": "Africa/Blantyre"
      }
    },
    {
      "id": "458",
      "name": "MALASIA",
      "translationKey": "COUNTRY.MYS",
      "disabled": true,
      "phone": "60",
      "ISOCode": "MYS",
      "flag": "./assets/media/svg/custom-flags/my.svg",
      "code": "MY",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MYR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MALAYSIA",
      "nameEn": "MALAYSIA",
      "config": {
        "currency": "MYR",
        "language": "es",
        "timeZone": "Asia/Kuala_Lumpur"
      }
    },
    {
      "id": "462",
      "name": "ISLAS MALDIVAS",
      "translationKey": "COUNTRY.MDV",
      "disabled": true,
      "phone": "960",
      "ISOCode": "MDV",
      "flag": "./assets/media/svg/custom-flags/mv.svg",
      "code": "MV",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MVR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MALDIVES",
      "nameEn": "MALDIVES",
      "config": {
        "currency": "MVR",
        "language": "es",
        "timeZone": "Indian/Maldives"
      }
    },
    {
      "id": "466",
      "name": "MALI",
      "translationKey": "COUNTRY.MLI",
      "disabled": true,
      "phone": "223",
      "ISOCode": "MLI",
      "flag": "./assets/media/svg/custom-flags/ml.svg",
      "code": "ML",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XOF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MALI",
      "nameEn": "MALI",
      "config": {
        "currency": "XOF",
        "language": "es",
        "timeZone": "Africa/Bamako"
      }
    },
    {
      "id": "470",
      "name": "MALTA",
      "translationKey": "COUNTRY.MLT",
      "disabled": true,
      "phone": "356",
      "ISOCode": "MLT",
      "flag": "./assets/media/svg/custom-flags/mt.svg",
      "code": "MT",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MALTA",
      "nameEn": "MALTA",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Malta"
      }
    },
    {
      "id": "584",
      "name": "ISLAS MARSHALL",
      "translationKey": "COUNTRY.MHL",
      "disabled": true,
      "phone": "692",
      "ISOCode": "MHL",
      "flag": "./assets/media/svg/custom-flags/mh.svg",
      "code": "MH",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MARSHALL ISLANDS",
      "nameEn": "MARSHALL ISLANDS",
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "Pacific/Kwajalein"
      }
    },
    {
      "id": "474",
      "name": "MARTINIQUE",
      "translationKey": "COUNTRY.MTQ",
      "disabled": true,
      "phone": "596",
      "ISOCode": "MTQ",
      "flag": "./assets/media/svg/custom-flags/mq.svg",
      "code": "MQ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "America/Martinique"
      }
    },
    {
      "id": "478",
      "name": "MAURITANIA",
      "translationKey": "COUNTRY.MRT",
      "disabled": true,
      "phone": "222",
      "ISOCode": "MRT",
      "flag": "./assets/media/svg/custom-flags/mr.svg",
      "code": "MR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MRU",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MAURITANIA",
      "nameEn": "MAURITANIA",
      "config": {
        "currency": "MRO",
        "language": "es",
        "timeZone": "Africa/Nouakchott"
      }
    },
    {
      "id": "480",
      "name": "MAURICIO",
      "translationKey": "COUNTRY.MUS",
      "disabled": true,
      "phone": "230",
      "ISOCode": "MUS",
      "flag": "./assets/media/svg/custom-flags/mu.svg",
      "code": "MU",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MAURITIUS",
      "nameEn": "MAURITIUS",
      "config": {
        "currency": "MUR",
        "language": "es",
        "timeZone": "Indian/Mauritius"
      }
    },
    {
      "id": "484",
      "name": "MÉXICO",
      "translationKey": "COUNTRY.MEX",
      "disabled": false,
      "phone": "52",
      "ISOCode": "MEX",
      "flag": "./assets/media/svg/custom-flags/mx.svg",
      "code": "MX",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MXN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "config": {
        "currency": "MXN",
        "language": "es",
        "timeZone": "America/Mexico_City"
      },
      "nameBack": "MÉXICO",
      "nameEn": "MEXICO"
    },
    {
      "id": "583",
      "name": "MICRONESIA",
      "translationKey": "COUNTRY.FSM",
      "disabled": true,
      "phone": "691",
      "ISOCode": "FSM",
      "flag": "./assets/media/svg/custom-flags/fm.svg",
      "code": "FM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MICRONESIA, FEDERATED STATES OF",
      "nameEn": "ESTADOS FEDERADOS DE",
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "Pacific/Chuuk"
      }
    },
    {
      "id": "498",
      "name": "MOLDAVIA",
      "translationKey": "COUNTRY.MDA",
      "disabled": true,
      "phone": "373",
      "ISOCode": "MDA",
      "flag": "./assets/media/svg/custom-flags/md.svg",
      "code": "MD",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MDL",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MOLDOVA, REPUBLIC OF",
      "nameEn": "MOLDOVA",
      "config": {
        "currency": "MDL",
        "language": "es",
        "timeZone": "Europe/Chisinau"
      }
    },
    {
      "id": "492",
      "name": "MÓNACO",
      "translationKey": "COUNTRY.MCO",
      "disabled": true,
      "phone": "377",
      "ISOCode": "MCO",
      "flag": "./assets/media/svg/custom-flags/mc.svg",
      "code": "MC",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MONACO",
      "nameEn": "MONACO",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Monaco"
      }
    },
    {
      "id": "496",
      "name": "MONGOLIA",
      "translationKey": "COUNTRY.MNG",
      "disabled": true,
      "phone": "976",
      "ISOCode": "MNG",
      "flag": "./assets/media/svg/custom-flags/mn.svg",
      "code": "MN",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MNT",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MONGOLIA",
      "nameEn": "MONGOLIA",
      "config": {
        "currency": "MNT",
        "language": "es",
        "timeZone": "Asia/Choibalsan"
      }
    },
    {
      "id": "500",
      "name": "MONTSERRAT",
      "translationKey": "COUNTRY.MSR",
      "disabled": true,
      "phone": "1664",
      "ISOCode": "MSR",
      "flag": "./assets/media/svg/custom-flags/ms.svg",
      "code": "MS",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XCD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MONTSERRAT",
      "nameEn": "MONTSERRAT",
      "config": {
        "currency": "XCD",
        "language": "es",
        "timeZone": "America/Montserrat"
      }
    },
    {
      "id": "504",
      "name": "MARRUECOS",
      "translationKey": "COUNTRY.MAR",
      "disabled": true,
      "phone": "212",
      "ISOCode": "MAR",
      "flag": "./assets/media/svg/custom-flags/ma.svg",
      "code": "MA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MAD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MOROCCO",
      "nameEn": "MOROCCO",
      "config": {
        "currency": "MAD",
        "language": "es",
        "timeZone": "Africa/Casablanca"
      }
    },
    {
      "id": "508",
      "name": "MOZAMBIQUE",
      "translationKey": "COUNTRY.MOZ",
      "disabled": true,
      "phone": "258",
      "ISOCode": "MOZ",
      "flag": "./assets/media/svg/custom-flags/mz.svg",
      "code": "MZ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MZN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MOZAMBIQUE",
      "nameEn": "MOZAMBIQUE",
      "config": {
        "currency": "MZN",
        "language": "es",
        "timeZone": "Africa/Maputo"
      }
    },
    {
      "id": "104",
      "name": "BIRMANIA",
      "translationKey": "COUNTRY.MMR",
      "disabled": true,
      "phone": "95",
      "ISOCode": "MMR",
      "flag": "./assets/media/svg/custom-flags/mm.svg",
      "code": "MM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MMK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "MYANMAR",
      "nameEn": "MYANMAR",
      "config": {
        "currency": "MMK",
        "language": "es",
        "timeZone": "Asia/Yangon"
      }
    },
    {
      "id": "516",
      "name": "NAMIBIA",
      "translationKey": "COUNTRY.NAM",
      "disabled": true,
      "phone": "264",
      "ISOCode": "NAM",
      "flag": "./assets/media/svg/custom-flags/na.svg",
      "code": "NA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "NAD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NAMIBIA",
      "nameEn": "NAMIBIA",
      "config": {
        "currency": "NAD",
        "language": "es",
        "timeZone": "Africa/Windhoek"
      }
    },
    {
      "id": "520",
      "name": "NAURU",
      "translationKey": "COUNTRY.NRU",
      "disabled": true,
      "phone": "674",
      "ISOCode": "NRU",
      "flag": "./assets/media/svg/custom-flags/nr.svg",
      "code": "NR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "AUD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NAURU",
      "nameEn": "NAURU",
      "config": {
        "currency": "AUD",
        "language": "es",
        "timeZone": "Pacific/Nauru"
      }
    },
    {
      "id": "524",
      "name": "NEPAL",
      "translationKey": "COUNTRY.NPL",
      "disabled": true,
      "phone": "977",
      "ISOCode": "NPL",
      "flag": "./assets/media/svg/custom-flags/np.svg",
      "code": "NP",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "NPR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NEPAL",
      "nameEn": "NEPAL",
      "config": {
        "currency": "NPR",
        "language": "es",
        "timeZone": "Asia/Kathmandu"
      }
    },
    {
      "id": "528",
      "name": "PAÍSES BAJOS",
      "translationKey": "COUNTRY.NLD",
      "disabled": true,
      "phone": "31",
      "ISOCode": "NLD",
      "flag": "./assets/media/svg/custom-flags/nl.svg",
      "code": "NL",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NETHERLANDS",
      "nameEn": "NETHERLANDS",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Amsterdam"
      }
    },
    {
      "id": "540",
      "name": "NUEVA CALEDONIA",
      "translationKey": "COUNTRY.NCL",
      "disabled": true,
      "phone": "687",
      "ISOCode": "NCL",
      "flag": "./assets/media/svg/custom-flags/nc.svg",
      "code": "NC",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XPF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NEW CALEDONIA",
      "nameEn": "NEW CALEDONIA",
      "config": {
        "currency": "XPF",
        "language": "es",
        "timeZone": "Pacific/Noumea"
      }
    },
    {
      "id": "554",
      "name": "NUEVA ZELANDA",
      "translationKey": "COUNTRY.NZL",
      "disabled": true,
      "phone": "64",
      "ISOCode": "NZL",
      "flag": "./assets/media/svg/custom-flags/nz.svg",
      "code": "NZ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "NZD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NEW ZEALAND",
      "nameEn": "NEW ZEALAND",
      "config": {
        "currency": "NZD",
        "language": "es",
        "timeZone": "Pacific/Auckland"
      }
    },
    {
      "id": "558",
      "name": "NICARAGUA",
      "translationKey": "COUNTRY.NIC",
      "disabled": false,
      "phone": "505",
      "ISOCode": "NIC",
      "flag": "./assets/media/svg/custom-flags/ni.svg",
      "code": "NI",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "NIO",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NICARAGUA",
      "nameEn": "NICARAGUA",
      "config": {
        "currency": "NIO",
        "language": "es",
        "timeZone": "America/Managua"
      }
    },
    {
      "id": "562",
      "name": "NIGER",
      "translationKey": "COUNTRY.NER",
      "disabled": true,
      "phone": "227",
      "ISOCode": "NER",
      "flag": "./assets/media/svg/custom-flags/ne.svg",
      "code": "NE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XOF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NIGER",
      "nameEn": "NIGER",
      "config": {
        "currency": "XOF",
        "language": "es",
        "timeZone": "Africa/Niamey"
      }
    },
    {
      "id": "566",
      "name": "NIGERIA",
      "translationKey": "COUNTRY.NGA",
      "disabled": true,
      "phone": "234",
      "ISOCode": "NGA",
      "flag": "./assets/media/svg/custom-flags/ng.svg",
      "code": "NG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "NGN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NIGERIA",
      "nameEn": "NIGERIA",
      "config": {
        "currency": "NGN",
        "language": "es",
        "timeZone": "Africa/Lagos"
      }
    },
    {
      "id": "570",
      "name": "NIUE",
      "translationKey": "COUNTRY.NIU",
      "disabled": true,
      "phone": "683",
      "ISOCode": "NIU",
      "flag": "./assets/media/svg/custom-flags/nu.svg",
      "code": "NU",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "NZD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NIUE",
      "nameEn": "NIUE",
      "config": {
        "currency": "NZD",
        "language": "es",
        "timeZone": "Pacific/Niue"
      }
    },
    {
      "id": "574",
      "name": "ANTÁRTIDA",
      "translationKey": "COUNTRY.NFK",
      "disabled": true,
      "phone": "672",
      "ISOCode": "NFK",
      "flag": "./assets/media/svg/custom-flags/nf.svg",
      "code": "NF",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "AUD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NORFOLK ISLAND",
      "nameEn": "ANTARCTICA",
      "config": {
        "currency": "AUD",
        "language": "es",
        "timeZone": "Pacific/Norfolk"
      }
    },
    {
      "id": "580",
      "name": "ISLAS MARIANAS DEL NORTE",
      "translationKey": "COUNTRY.MNP",
      "disabled": true,
      "phone": "1670",
      "ISOCode": "MNP",
      "flag": "./assets/media/svg/custom-flags/mp.svg",
      "code": "MP",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NORTHERN MARIANA ISLANDS",
      "nameEn": "NORTHERN MARIANA ISLANDS",
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "Pacific/Saipan"
      }
    },
    {
      "id": "578",
      "name": "NORUEGA",
      "translationKey": "COUNTRY.NOR",
      "disabled": true,
      "phone": "47",
      "ISOCode": "NOR",
      "flag": "./assets/media/svg/custom-flags/no.svg",
      "code": "NO",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "NOK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "NORWAY",
      "nameEn": "NORWAY",
      "config": {
        "currency": "NOK",
        "language": "es",
        "timeZone": "Europe/Oslo"
      }
    },
    {
      "id": "512",
      "name": "OMÁN",
      "translationKey": "COUNTRY.OMN",
      "disabled": true,
      "phone": "968",
      "ISOCode": "OMN",
      "flag": "./assets/media/svg/custom-flags/om.svg",
      "code": "OM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "OMR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "OMAN",
      "nameEn": "OMAN",
      "config": {
        "currency": "OMR",
        "language": "es",
        "timeZone": "Asia/Muscat"
      }
    },
    {
      "id": "586",
      "name": "PAKISTÁN",
      "translationKey": "COUNTRY.PAK",
      "disabled": true,
      "phone": "92",
      "ISOCode": "PAK",
      "flag": "./assets/media/svg/custom-flags/pk.svg",
      "code": "PK",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "PKR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "PAKISTAN",
      "nameEn": "PAKISTAN",
      "config": {
        "currency": "PKR",
        "language": "es",
        "timeZone": "Asia/Karachi"
      }
    },
    {
      "id": "585",
      "name": "PALAU",
      "translationKey": "COUNTRY.PLW",
      "disabled": true,
      "phone": "680",
      "ISOCode": "PLW",
      "flag": "./assets/media/svg/custom-flags/pw.svg",
      "code": "PW",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "PALAU",
      "nameEn": "PALAU",
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "Pacific/Palau"
      }
    },
    {
      "id": "591",
      "name": "PANAMÁ",
      "translationKey": "COUNTRY.PAN",
      "disabled": false,
      "phone": "507",
      "ISOCode": "PAN",
      "flag": "./assets/media/svg/custom-flags/pa.svg",
      "code": "PA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "PAB",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "config": {
        "currency": "PAB",
        "language": "es",
        "timeZone": "America/Panama"
      },
      "nameBack": "PANAMA",
      "nameEn": "PANAMA"
    },
    {
      "id": "598",
      "name": "PAPÚA NUEVA GUINEA",
      "translationKey": "COUNTRY.PNG",
      "disabled": true,
      "phone": "675",
      "ISOCode": "PNG",
      "flag": "./assets/media/svg/custom-flags/pg.svg",
      "code": "PG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "PGK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "PAPUA NEW GUINEA",
      "nameEn": "PAPUA NEW GUINEA",
      "config": {
        "currency": "PGK",
        "language": "es",
        "timeZone": "Pacific/Bougainville"
      }
    },
    {
      "id": "600",
      "name": "PARAGUAY",
      "translationKey": "COUNTRY.PRY",
      "disabled": false,
      "phone": "595",
      "ISOCode": "PRY",
      "flag": "./assets/media/svg/custom-flags/py.svg",
      "code": "PY",
      "config": {
        "currency": "PYG",
        "language": "es",
        "timeZone": "America/Asuncion"
      },
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "PYG",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "PARAGUAY",
      "nameEn": "PARAGUAY"
    },
    {
      "id": "604",
      "name": "PERÚ",
      "translationKey": "COUNTRY.PER",
      "disabled": false,
      "phone": "51",
      "ISOCode": "PER",
      "flag": "./assets/media/svg/custom-flags/pe.svg",
      "code": "PE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "PEN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "config": {
        "currency": "PEN",
        "language": "es",
        "timeZone": "America/Lima"
      },
      "nameBack": "PERÚ",
      "nameEn": "PERU"
    },
    {
      "id": "608",
      "name": "FILIPINAS",
      "translationKey": "COUNTRY.PHL",
      "disabled": true,
      "phone": "63",
      "ISOCode": "PHL",
      "flag": "./assets/media/svg/custom-flags/ph.svg",
      "code": "PH",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "PHP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "PHILIPPINES",
      "nameEn": "PHILIPPINES",
      "config": {
        "currency": "PHP",
        "language": "es",
        "timeZone": "Asia/Manila"
      }
    },
    {
      "id": "612",
      "name": "PITCAIRN",
      "translationKey": "COUNTRY.PCN",
      "disabled": true,
      "phone": "0",
      "ISOCode": "PCN",
      "flag": "./assets/media/svg/custom-flags/pn.svg",
      "code": "PN",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "NZD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "config": {
        "currency": "NZD",
        "language": "es",
        "timeZone": "Pacific/Pitcairn"
      }
    },
    {
      "id": "616",
      "name": "POLONIA",
      "translationKey": "COUNTRY.POL",
      "disabled": true,
      "phone": "48",
      "ISOCode": "POL",
      "flag": "./assets/media/svg/custom-flags/pl.svg",
      "code": "PL",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "PLN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "POLAND",
      "nameEn": "POLAND",
      "config": {
        "currency": "PLN",
        "language": "es",
        "timeZone": "Europe/Warsaw"
      }
    },
    {
      "id": "620",
      "name": "PORTUGAL",
      "translationKey": "COUNTRY.PRT",
      "disabled": true,
      "phone": "351",
      "ISOCode": "PRT",
      "flag": "./assets/media/svg/custom-flags/pt.svg",
      "code": "PT",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "PORTUGAL",
      "nameEn": "PORTUGAL",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Lisbon"
      }
    },
    {
      "id": "630",
      "name": "PUERTO RICO",
      "translationKey": "COUNTRY.PRI",
      "disabled": false,
      "phone": "1787",
      "ISOCode": "PRI",
      "flag": "./assets/media/svg/custom-flags/pr.svg",
      "code": "PR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "America/Puerto_Rico"
      }
    },
    {
      "id": "634",
      "name": "QATAR",
      "translationKey": "COUNTRY.QAT",
      "disabled": true,
      "phone": "974",
      "ISOCode": "QAT",
      "flag": "./assets/media/svg/custom-flags/qa.svg",
      "code": "QA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "QAR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "QATAR",
      "nameEn": "QATAR",
      "config": {
        "currency": "QAR",
        "language": "es",
        "timeZone": "Asia/Qatar"
      }
    },
    {
      "id": "638",
      "name": "MAYOTTE",
      "translationKey": "COUNTRY.REU",
      "disabled": true,
      "phone": "262",
      "ISOCode": "REU",
      "flag": "./assets/media/svg/custom-flags/re.svg",
      "code": "RE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "REUNION",
      "nameEn": "MAYOTTE",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Indian/Reunion"
      }
    },
    {
      "id": "642",
      "name": "RUMANÍA",
      "translationKey": "COUNTRY.ROM",
      "disabled": true,
      "phone": "40",
      "ISOCode": "ROM",
      "flag": "./assets/media/svg/custom-flags/ro.svg",
      "code": "RO",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "RON",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ROMANIA",
      "nameEn": "ROMANIA",
      "config": {
        "currency": "RON",
        "language": "es",
        "timeZone": "Europe/Bucharest"
      }
    },
    {
      "id": "643",
      "name": "RUSSIAN FEDERATION",
      "translationKey": "COUNTRY.RUS",
      "disabled": true,
      "phone": "70",
      "ISOCode": "RUS",
      "flag": "./assets/media/svg/custom-flags/ru.svg",
      "code": "RU",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "RUB",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "config": {
        "currency": "RUB",
        "language": "es",
        "timeZone": "urope/Moscow"
      }
    },
    {
      "id": "646",
      "name": "RUANDA",
      "translationKey": "COUNTRY.RWA",
      "disabled": true,
      "phone": "250",
      "ISOCode": "RWA",
      "flag": "./assets/media/svg/custom-flags/rw.svg",
      "code": "RW",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "RWF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "RWANDA",
      "nameEn": "RWANDA",
      "config": {
        "currency": "RWF",
        "language": "es",
        "timeZone": "Africa/Kigali"
      }
    },
    {
      "id": "654",
      "name": "SANTA ELENA",
      "translationKey": "COUNTRY.SHN",
      "disabled": true,
      "phone": "290",
      "ISOCode": "SHN",
      "flag": "./assets/media/svg/custom-flags/sh.svg",
      "code": "SH",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SHP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SAINT HELENA",
      "nameEn": "ASCENSIÓN Y TRISTÁN DE ACUÑA",
      "config": {
        "currency": "SHP",
        "language": "es",
        "timeZone": "Atlantic/St_Helena"
      }
    },
    {
      "id": "659",
      "name": "SAN CRISTÓBAL Y NIEVES",
      "translationKey": "COUNTRY.KNA",
      "disabled": true,
      "phone": "1869",
      "ISOCode": "KNA",
      "flag": "./assets/media/svg/custom-flags/kn.svg",
      "code": "KN",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XCD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SAINT KITTS AND NEVIS",
      "nameEn": "SAINT KITTS AND NEVIS",
      "config": {
        "currency": "XCD",
        "language": "es",
        "timeZone": "America/St_Kitts"
      }
    },
    {
      "id": "662",
      "name": "SANTA LUCÍA",
      "translationKey": "COUNTRY.LCA",
      "disabled": true,
      "phone": "1758",
      "ISOCode": "LCA",
      "flag": "./assets/media/svg/custom-flags/lc.svg",
      "code": "LC",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XCD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SAINT LUCIA",
      "nameEn": "SAINT LUCIA",
      "config": {
        "currency": "XCD",
        "language": "es",
        "timeZone": "America/St_Lucia"
      }
    },
    {
      "id": "666",
      "name": "SAN PEDRO Y MIQUELÓN",
      "translationKey": "COUNTRY.SPM",
      "disabled": true,
      "phone": "508",
      "ISOCode": "SPM",
      "flag": "./assets/media/svg/custom-flags/pm.svg",
      "code": "PM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SAINT PIERRE AND MIQUELON",
      "nameEn": "SAINT PIERRE AND MIQUELON",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "America/Miquelon"
      }
    },
    {
      "id": "670",
      "name": "SAN VICENTE Y LAS GRANADINAS",
      "translationKey": "COUNTRY.VCT",
      "disabled": true,
      "phone": "1784",
      "ISOCode": "VCT",
      "flag": "./assets/media/svg/custom-flags/vc.svg",
      "code": "VC",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XCD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SAINT VINCENT AND THE GRENADINES",
      "nameEn": "SAINT VINCENT AND THE GRENADINES",
      "config": {
        "currency": "XCD",
        "language": "es",
        "timeZone": "America/St_Vincent"
      }
    },
    {
      "id": "882",
      "name": "SAMOA",
      "translationKey": "COUNTRY.WSM",
      "disabled": true,
      "phone": "684",
      "ISOCode": "WSM",
      "flag": "./assets/media/svg/custom-flags/ws.svg",
      "code": "WS",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "WST",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "config": {
        "currency": "WST",
        "language": "es",
        "timeZone": "Pacific/Apia"
      }
    },
    {
      "id": "674",
      "name": "SAN MARINO",
      "translationKey": "COUNTRY.SMR",
      "disabled": true,
      "phone": "378",
      "ISOCode": "SMR",
      "flag": "./assets/media/svg/custom-flags/sm.svg",
      "code": "SM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SAN MARINO",
      "nameEn": "SAN MARINO",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/San_Marino"
      }
    },
    {
      "id": "678",
      "name": "SANTO TOMÉ Y PRÍNCIPE",
      "translationKey": "COUNTRY.STP",
      "disabled": true,
      "phone": "239",
      "ISOCode": "STP",
      "flag": "./assets/media/svg/custom-flags/st.svg",
      "code": "ST",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "STN",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SAO TOME AND PRINCIPE",
      "nameEn": "SAO TOME AND PRINCIPE",
      "config": {
        "currency": "STD",
        "language": "es",
        "timeZone": "Africa/Sao_Tome"
      }
    },
    {
      "id": "682",
      "name": "ARABIA SAUDITA",
      "translationKey": "COUNTRY.SAU",
      "disabled": true,
      "phone": "966",
      "ISOCode": "SAU",
      "flag": "./assets/media/svg/custom-flags/sa.svg",
      "code": "SA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SAR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SAUDI ARABIA",
      "nameEn": "SAUDI ARABIA",
      "config": {
        "currency": "SAR",
        "language": "es",
        "timeZone": "Asia/Riyadh"
      }
    },
    {
      "id": "686",
      "name": "SENEGAL",
      "translationKey": "COUNTRY.SEN",
      "disabled": true,
      "phone": "221",
      "ISOCode": "SEN",
      "flag": "./assets/media/svg/custom-flags/sn.svg",
      "code": "SN",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XOF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SENEGAL",
      "nameEn": "SENEGAL",
      "config": {
        "currency": "XOF",
        "language": "es",
        "timeZone": "Africa/Dakar"
      }
    },
    {
      "id": "690",
      "name": "SEYCHELLES",
      "translationKey": "COUNTRY.SYC",
      "disabled": true,
      "phone": "248",
      "ISOCode": "SYC",
      "flag": "./assets/media/svg/custom-flags/sc.svg",
      "code": "SC",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SCR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SEYCHELLES",
      "nameEn": "SEYCHELLES",
      "config": {
        "currency": "SCR",
        "language": "es",
        "timeZone": "Indian/Mahe"
      }
    },
    {
      "id": "694",
      "name": "SIERRA LEONA",
      "translationKey": "COUNTRY.SLE",
      "disabled": true,
      "phone": "232",
      "ISOCode": "SLE",
      "flag": "./assets/media/svg/custom-flags/sl.svg",
      "code": "SL",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SLL",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SIERRA LEONE",
      "nameEn": "SIERRA LEONE",
      "config": {
        "currency": "SLL",
        "language": "es",
        "timeZone": "Africa/Freetown"
      }
    },
    {
      "id": "702",
      "name": "SINGAPUR",
      "translationKey": "COUNTRY.SGP",
      "disabled": true,
      "phone": "65",
      "ISOCode": "SGP",
      "flag": "./assets/media/svg/custom-flags/sg.svg",
      "code": "SG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SGD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SINGAPORE",
      "nameEn": "SINGAPORE",
      "config": {
        "currency": "SGD",
        "language": "es",
        "timeZone": "Asia/Singapore"
      }
    },
    {
      "id": "703",
      "name": "ESLOVAQUIA",
      "translationKey": "COUNTRY.SVK",
      "disabled": true,
      "phone": "421",
      "ISOCode": "SVK",
      "flag": "./assets/media/svg/custom-flags/sk.svg",
      "code": "SK",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SLOVAKIA",
      "nameEn": "SLOVAKIA",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Bratislava"
      }
    },
    {
      "id": "705",
      "name": "ESLOVENIA",
      "translationKey": "COUNTRY.SVN",
      "disabled": true,
      "phone": "386",
      "ISOCode": "SVN",
      "flag": "./assets/media/svg/custom-flags/si.svg",
      "code": "SI",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SLOVENIA",
      "nameEn": "SLOVENIA",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Ljubljana"
      }
    },
    {
      "id": "090",
      "name": "ISLAS SALOMÓN",
      "translationKey": "COUNTRY.SLB",
      "disabled": true,
      "phone": "677",
      "ISOCode": "SLB",
      "flag": "./assets/media/svg/custom-flags/sb.svg",
      "code": "SB",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SBD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SOLOMON ISLANDS",
      "nameEn": "SOLOMON ISLANDS",
      "config": {
        "currency": "SBD",
        "language": "es",
        "timeZone": "Pacific/Guadalcanal"
      }
    },
    {
      "id": "706",
      "name": "SOMALIA",
      "translationKey": "COUNTRY.SOM",
      "disabled": true,
      "phone": "252",
      "ISOCode": "SOM",
      "flag": "./assets/media/svg/custom-flags/so.svg",
      "code": "SO",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SOS",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SOMALIA",
      "nameEn": "SOMALIA",
      "config": {
        "currency": "SOS",
        "language": "es",
        "timeZone": "Africa/Mogadishu"
      }
    },
    {
      "id": "710",
      "name": "SUDÁFRICA",
      "translationKey": "COUNTRY.ZAF",
      "disabled": true,
      "phone": "27",
      "ISOCode": "ZAF",
      "flag": "./assets/media/svg/custom-flags/za.svg",
      "code": "ZA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "ZAR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SOUTH AFRICA",
      "nameEn": "SOUTH AFRICA",
      "config": {
        "currency": "ZAR",
        "language": "es",
        "timeZone": "Africa/Johannesburg"
      }
    },
    {
      "id": "724",
      "name": "ESPAÑA",
      "translationKey": "COUNTRY.ESP",
      "disabled": false,
      "phone": "34",
      "ISOCode": "ESP",
      "flag": "./assets/media/svg/custom-flags/es.svg",
      "code": "ES",
      "config": {
        "currency": "EUR",
        "language": "es",
        "timeZone": "Europe/Madrid"
      },
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "EUR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SPAIN",
      "nameEn": "SPAIN"
    },
    {
      "id": "144",
      "name": "SRI LANKA",
      "translationKey": "COUNTRY.LKA",
      "disabled": true,
      "phone": "94",
      "ISOCode": "LKA",
      "flag": "./assets/media/svg/custom-flags/lk.svg",
      "code": "LK",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "LKR",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SRI LANKA",
      "nameEn": "SRI LANKA",
      "config": {
        "currency": "LKR",
        "language": "es",
        "timeZone": "Asia/Colombo"
      }
    },
    {
      "id": "736",
      "name": "SUDÁN",
      "translationKey": "COUNTRY.SDN",
      "disabled": true,
      "phone": "249",
      "ISOCode": "SDN",
      "flag": "./assets/media/svg/custom-flags/sd.svg",
      "code": "SD",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SDG",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SUDAN",
      "nameEn": "SUDAN",
      "config": {
        "currency": "SDG",
        "language": "es",
        "timeZone": "Africa/Khartoum"
      }
    },
    {
      "id": "740",
      "name": "SURINÁM",
      "translationKey": "COUNTRY.SUR",
      "disabled": true,
      "phone": "597",
      "ISOCode": "SUR",
      "flag": "./assets/media/svg/custom-flags/sr.svg",
      "code": "SR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SRD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SURINAME",
      "nameEn": "SURINAME",
      "config": {
        "currency": "SRD",
        "language": "es",
        "timeZone": "America/Paramaribo"
      }
    },
    {
      "id": "744",
      "name": "SVALBARD AND JAN MAYEN",
      "translationKey": "COUNTRY.SJM",
      "disabled": true,
      "phone": "47",
      "ISOCode": "SJM",
      "flag": "./assets/media/svg/custom-flags/sj.svg",
      "code": "SJ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "NOK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SVALBARD AND JAN MAYEN",
      "nameEn": "NORWAY",
      "config": {
        "currency": "NOK",
        "language": "es",
        "timeZone": "Arctic/Longyearbyen"
      }
    },
    {
      "id": "748",
      "name": "SWAZILANDIA",
      "translationKey": "COUNTRY.SWZ",
      "disabled": true,
      "phone": "268",
      "ISOCode": "SWZ",
      "flag": "./assets/media/svg/custom-flags/sz.svg",
      "code": "SZ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SZL",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SWAZILAND",
      "nameEn": "SWAZILAND",
      "config": {
        "currency": "SZL",
        "language": "es",
        "timeZone": "Africa/Mbabane"
      }
    },
    {
      "id": "752",
      "name": "SUECIA",
      "translationKey": "COUNTRY.SWE",
      "disabled": true,
      "phone": "46",
      "ISOCode": "SWE",
      "flag": "./assets/media/svg/custom-flags/se.svg",
      "code": "SE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SEK",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SWEDEN",
      "nameEn": "SWEDEN",
      "config": {
        "currency": "SEK",
        "language": "es",
        "timeZone": "Europe/Stockholm"
      }
    },
    {
      "id": "756",
      "name": "SUIZA",
      "translationKey": "COUNTRY.CHE",
      "disabled": true,
      "phone": "41",
      "ISOCode": "CHE",
      "flag": "./assets/media/svg/custom-flags/ch.svg",
      "code": "CH",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "CHF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SWITZERLAND",
      "nameEn": "SWITZERLAND",
      "config": {
        "currency": "CHE",
        "language": "es",
        "timeZone": "Europe/Zurich"
      }
    },
    {
      "id": "760",
      "name": "SIRIA",
      "translationKey": "COUNTRY.SYR",
      "disabled": true,
      "phone": "963",
      "ISOCode": "SYR",
      "flag": "./assets/media/svg/custom-flags/sy.svg",
      "code": "SY",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "SYP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "SYRIAN ARAB REPUBLIC",
      "nameEn": "SYRIA",
      "config": {
        "currency": "SYP",
        "language": "es",
        "timeZone": "Asia/Damascus"
      }
    },
    {
      "id": "158",
      "name": "TAIWÁN",
      "translationKey": "COUNTRY.TWN",
      "disabled": true,
      "phone": "886",
      "ISOCode": "TWN",
      "flag": "./assets/media/svg/custom-flags/tw.svg",
      "code": "TW",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "TWD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "TAIWAN, PROVINCE OF CHINA",
      "nameEn": "TAIWAN",
      "config": {
        "currency": "TWD",
        "language": "es",
        "timeZone": "Asia/Taipei"
      }
    },
    {
      "id": "762",
      "name": "TADJIKISTÁN",
      "translationKey": "COUNTRY.TJK",
      "disabled": true,
      "phone": "992",
      "ISOCode": "TJK",
      "flag": "./assets/media/svg/custom-flags/tj.svg",
      "code": "TJ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "TJS",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "TAJIKISTAN",
      "nameEn": "TAJIKISTAN",
      "config": {
        "currency": "TJS",
        "language": "es",
        "timeZone": "Asia/Dushanbe"
      }
    },
    {
      "id": "834",
      "name": "TANZANIA",
      "translationKey": "COUNTRY.TZA",
      "disabled": true,
      "phone": "255",
      "ISOCode": "TZA",
      "flag": "./assets/media/svg/custom-flags/tz.svg",
      "code": "TZ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "TZS",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "TANZANIA, UNITED REPUBLIC OF",
      "nameEn": "TANZANIA",
      "config": {
        "currency": "TZS",
        "language": "es",
        "timeZone": "Africa/Dar_es_Salaam"
      }
    },
    {
      "id": "764",
      "name": "TAILANDIA",
      "translationKey": "COUNTRY.THA",
      "disabled": true,
      "phone": "66",
      "ISOCode": "THA",
      "flag": "./assets/media/svg/custom-flags/th.svg",
      "code": "TH",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "THB",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "THAILAND",
      "nameEn": "THAILAND",
      "config": {
        "currency": "THB",
        "language": "es",
        "timeZone": "Asia/Bangkok"
      }
    },
    {
      "id": "768",
      "name": "TOGO",
      "translationKey": "COUNTRY.TGO",
      "disabled": true,
      "phone": "228",
      "ISOCode": "TGO",
      "flag": "./assets/media/svg/custom-flags/tg.svg",
      "code": "TG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XOF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "TOGO",
      "nameEn": "TOGO",
      "config": {
        "currency": "XOF",
        "language": "es",
        "timeZone": "Africa/Lome"
      }
    },
    {
      "id": "772",
      "name": "TOKELAU",
      "translationKey": "COUNTRY.TKL",
      "disabled": true,
      "phone": "690",
      "ISOCode": "TKL",
      "flag": "./assets/media/svg/custom-flags/tk.svg",
      "code": "TK",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "NZD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "TOKELAU",
      "nameEn": "TOKELAU",
      "config": {
        "currency": "NZD",
        "language": "es",
        "timeZone": "Pacific/Fakaofo"
      }
    },
    {
      "id": "776",
      "name": "TONGA",
      "translationKey": "COUNTRY.TON",
      "disabled": true,
      "phone": "676",
      "ISOCode": "TON",
      "flag": "./assets/media/svg/custom-flags/to.svg",
      "code": "TO",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "TOP",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "TONGA",
      "nameEn": "TONGA",
      "config": {
        "currency": "TOP",
        "language": "es",
        "timeZone": "Pacific/Tongatapu"
      }
    },
    {
      "id": "780",
      "name": "TRINIDAD Y TOBAGO",
      "translationKey": "COUNTRY.TTO",
      "disabled": true,
      "phone": "1868",
      "ISOCode": "TTO",
      "flag": "./assets/media/svg/custom-flags/tt.svg",
      "code": "TT",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "TTD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "TRINIDAD AND TOBAGO",
      "nameEn": "TRINIDAD AND TOBAGO",
      "config": {
        "currency": "TTD",
        "language": "es",
        "timeZone": "America/Port_of_Spain"
      }
    },
    {
      "id": "788",
      "name": "TUNEZ",
      "translationKey": "COUNTRY.TUN",
      "disabled": true,
      "phone": "216",
      "ISOCode": "TUN",
      "flag": "./assets/media/svg/custom-flags/tn.svg",
      "code": "TN",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "TND",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "TUNISIA",
      "nameEn": "TUNISIA",
      "config": {
        "currency": "TND",
        "language": "es",
        "timeZone": "Africa/Tunis"
      }
    },
    {
      "id": "792",
      "name": "TURQUÍA",
      "translationKey": "COUNTRY.TUR",
      "disabled": true,
      "phone": "90",
      "ISOCode": "TUR",
      "flag": "./assets/media/svg/custom-flags/tr.svg",
      "code": "TR",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "TRY",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "TURKEY",
      "nameEn": "TURKEY",
      "config": {
        "currency": "TRY",
        "language": "es",
        "timeZone": "Europe/Istanbul"
      }
    },
    {
      "id": "795",
      "name": "TURKMENISTAN",
      "translationKey": "COUNTRY.TKM",
      "disabled": true,
      "phone": "7370",
      "ISOCode": "TKM",
      "flag": "./assets/media/svg/custom-flags/tm.svg",
      "code": "TM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "TMT",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "config": {
        "currency": "TMT",
        "language": "es",
        "timeZone": "Asia/Ashgabat"
      }
    },
    {
      "id": "796",
      "name": "ISLAS TURCAS Y CAICOS",
      "translationKey": "COUNTRY.TCA",
      "disabled": true,
      "phone": "1649",
      "ISOCode": "TCA",
      "flag": "./assets/media/svg/custom-flags/tc.svg",
      "code": "TC",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "TURKS AND CAICOS ISLANDS",
      "nameEn": "TURKS AND CAICOS ISLANDS",
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "America/Grand_Turk"
      }
    },
    {
      "id": "798",
      "name": "TUVALU",
      "translationKey": "COUNTRY.TUV",
      "disabled": true,
      "phone": "688",
      "ISOCode": "TUV",
      "flag": "./assets/media/svg/custom-flags/tv.svg",
      "code": "TV",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "AUD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "TUVALU",
      "nameEn": "TUVALU",
      "config": {
        "currency": "AUD",
        "language": "es",
        "timeZone": "Pacific/Funafuti"
      }
    },
    {
      "id": "800",
      "name": "UGANDA",
      "translationKey": "COUNTRY.UGA",
      "disabled": true,
      "phone": "256",
      "ISOCode": "UGA",
      "flag": "./assets/media/svg/custom-flags/ug.svg",
      "code": "UG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "UGX",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "UGANDA",
      "nameEn": "UGANDA",
      "config": {
        "currency": "UGX",
        "language": "es",
        "timeZone": "Africa/Kampala"
      }
    },
    {
      "id": "804",
      "name": "UCRANIA",
      "translationKey": "COUNTRY.UKR",
      "disabled": true,
      "phone": "380",
      "ISOCode": "UKR",
      "flag": "./assets/media/svg/custom-flags/ua.svg",
      "code": "UA",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "UAH",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "UKRAINE",
      "nameEn": "UKRAINE",
      "config": {
        "currency": "UAH",
        "language": "es",
        "timeZone": "Europe/Kiev"
      }
    },
    {
      "id": "784",
      "name": "EMIRATOS ÁRABES UNIDOS",
      "translationKey": "COUNTRY.ARE",
      "disabled": true,
      "phone": "971",
      "ISOCode": "ARE",
      "flag": "./assets/media/svg/custom-flags/ae.svg",
      "code": "AE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "AED",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "UNITED ARAB EMIRATES",
      "nameEn": "UNITED ARAB EMIRATES",
      "config": {
        "currency": "AED",
        "language": "es",
        "timeZone": "Asia/Dubai"
      }
    },
    {
      "id": "826",
      "name": "REINO UNIDO",
      "translationKey": "COUNTRY.GBR",
      "disabled": true,
      "phone": "44",
      "ISOCode": "GBR",
      "flag": "./assets/media/svg/custom-flags/gb.svg",
      "code": "GB",
      "currencies": [],
      "nameBack": "ISLA DE MAN",
      "nameEn": "UNITED KINGDOM",
      "config": {
        "currency": "GBP",
        "language": "es",
        "timeZone": "Europe/London"
      }
    },
    {
      "id": "840",
      "name": "ESTADOS UNIDOS DE AMÉRICA",
      "translationKey": "COUNTRY.USA",
      "disabled": false,
      "phone": "1",
      "ISOCode": "USA",
      "flag": "./assets/media/svg/custom-flags/us.svg",
      "code": "US",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ESTADOS UNIDOS DE AMÉRICA",
      "nameEn": "UNITED STATES OF AMERICA",
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "America/New_York"
      }
    },
    {
      "id": "858",
      "name": "URUGUAY",
      "translationKey": "COUNTRY.URY",
      "disabled": false,
      "phone": "598",
      "ISOCode": "URY",
      "flag": "./assets/media/svg/custom-flags/uy.svg",
      "code": "UY",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "UYU",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "URUGUAY",
      "nameEn": "URUGUAY",
      "config": {
        "currency": "UYU",
        "language": "es",
        "timeZone": "America/Montevideo"
      }
    },
    {
      "id": "860",
      "name": "UZBEKISTÁN",
      "translationKey": "COUNTRY.UZB",
      "disabled": true,
      "phone": "998",
      "ISOCode": "UZB",
      "flag": "./assets/media/svg/custom-flags/uz.svg",
      "code": "UZ",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "UZS",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "UZBEKISTAN",
      "nameEn": "UZBEKISTAN",
      "config": {
        "currency": "UZS",
        "language": "es",
        "timeZone": "Asia/Samarkand"
      }
    },
    {
      "id": "548",
      "name": "VANUATU",
      "translationKey": "COUNTRY.VUT",
      "disabled": true,
      "phone": "678",
      "ISOCode": "VUT",
      "flag": "./assets/media/svg/custom-flags/vu.svg",
      "code": "VU",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "VUV",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "VANUATU",
      "nameEn": "VANUATU",
      "config": {
        "currency": "VUV",
        "language": "es",
        "timeZone": "Pacific/Efate"
      }
    },
    {
      "id": "862",
      "name": "VENEZUELA",
      "translationKey": "COUNTRY.VEN",
      "disabled": false,
      "phone": "58",
      "ISOCode": "VEN",
      "flag": "./assets/media/svg/custom-flags/ve.svg",
      "code": "VE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "VES",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "VENEZUELA",
      "nameEn": "VENEZUELA",
      "config": {
        "currency": "VEF",
        "language": "es",
        "timeZone": "America/Caracas"
      }
    },
    {
      "id": "704",
      "name": "VIETNAM",
      "translationKey": "COUNTRY.VNM",
      "disabled": true,
      "phone": "84",
      "ISOCode": "VNM",
      "flag": "./assets/media/svg/custom-flags/vn.svg",
      "code": "VN",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "VND",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "VIET NAM",
      "nameEn": "VIETNAM",
      "config": {
        "currency": "VND",
        "language": "es",
        "timeZone": "Asia/Ho_Chi_Minh"
      }
    },
    {
      "id": "092",
      "name": "ISLAS VÍRGENES BRITÁNICAS",
      "translationKey": "COUNTRY.VGB",
      "disabled": true,
      "phone": "1284",
      "ISOCode": "VGB",
      "flag": "./assets/media/svg/custom-flags/vg.svg",
      "code": "VG",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "VIRGIN ISLANDS, BRITISH",
      "nameEn": "VIRGIN ISLANDS",
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "America/Tortola"
      }
    },
    {
      "id": "850",
      "name": "ISLAS VÍRGENES DE LOS ESTADOS UNIDOS",
      "translationKey": "COUNTRY.VIR",
      "disabled": true,
      "phone": "1340",
      "ISOCode": "VIR",
      "flag": "./assets/media/svg/custom-flags/vi.svg",
      "code": "VI",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "VIRGIN ISLANDS, U.S.",
      "nameEn": "UNITED STATES VIRGIN ISLANDS",
      "config": {
        "currency": "USD",
        "language": "es",
        "timeZone": "America/St_Thomas"
      }
    },
    {
      "id": "876",
      "name": "WALLIS Y FUTUNA",
      "translationKey": "COUNTRY.WLF",
      "disabled": true,
      "phone": "681",
      "ISOCode": "WLF",
      "flag": "./assets/media/svg/custom-flags/wf.svg",
      "code": "WF",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "XPF",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "WALLIS AND FUTUNA",
      "nameEn": "WALLIS AND FUTUNA",
      "config": {
        "currency": "XPF",
        "language": "es",
        "timeZone": "Pacific/Wallis"
      }
    },
    {
      "id": "732",
      "name": "SAHARA OCCIDENTAL",
      "translationKey": "COUNTRY.ESH",
      "disabled": true,
      "phone": "212",
      "ISOCode": "ESH",
      "flag": "./assets/media/svg/custom-flags/eh.svg",
      "code": "EH",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "MAD",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "WESTERN SAHARA",
      "nameEn": "MOROCCO",
      "config": {
        "currency": "MAD",
        "language": "es",
        "timeZone": "Africa/El_Aaiun"
      }
    },
    {
      "id": "887",
      "name": "YEMEN",
      "translationKey": "COUNTRY.YEM",
      "disabled": true,
      "phone": "967",
      "ISOCode": "YEM",
      "flag": "./assets/media/svg/custom-flags/ye.svg",
      "code": "YE",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "YER",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "YEMEN",
      "nameEn": "YEMEN",
      "config": {
        "currency": "YER",
        "language": "es",
        "timeZone": "Asia/Aden"
      }
    },
    {
      "id": "894",
      "name": "ZAMBIA",
      "translationKey": "COUNTRY.ZMB",
      "disabled": true,
      "phone": "260",
      "ISOCode": "ZMB",
      "flag": "./assets/media/svg/custom-flags/zm.svg",
      "code": "ZM",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "ZMW",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ZAMBIA",
      "nameEn": "ZAMBIA",
      "config": {
        "currency": "ZMW",
        "language": "es",
        "timeZone": "Africa/Lusaka"
      }
    },
    {
      "id": "716",
      "name": "ZIMBABUE",
      "translationKey": "COUNTRY.ZWE",
      "disabled": true,
      "phone": "263",
      "ISOCode": "ZWE",
      "flag": "./assets/media/svg/custom-flags/zw.svg",
      "code": "ZW",
      "currencies": [
        {
          "code": "USD",
          "excludeIfSecondaryCurrency": []
        },
        {
          "code": "ZWL",
          "excludeIfSecondaryCurrency": []
        }
      ],
      "nameBack": "ZIMBABWE",
      "nameEn": "ZIMBABWE",
      "config": {
        "currency": "ZWL",
        "language": "es",
        "timeZone": "Africa/Harare"
      }
    }
  ]
