export class OnboardingStep2Data{
    onboardingCall : OnboardingCall
    file : UploadFile[];
    uploadMethodType : string;
    onboardingVideoSeen : boolean;
    currentSubStep : string;
    onboardingUserScheduleUrl : string;
}

export class UploadFile{
    originalFileName : string;
    fileUrl : string;
    uploadedStep? : number;
}

export class OnboardingCall{
    status : string;
    scheduleCallDate : Date | null;
    callAgentName : string;
    recordedCallUrl? : string;
}

export class Flags{
    accordionCompany : boolean;
    accordionAccountsReceivable : boolean;
    accordionUploadMethod : boolean;
    accordionCollectionManagement : boolean;
    enablePreSettings : boolean;
    enablePlatform : boolean;
    onboardingVideoSeen : boolean;
    enableStep3Menu : boolean;
    step1Finished : boolean;
    step2Finished : boolean;
    step3Finished : boolean;
    step4Finished : boolean;
    alertDisplayed : boolean;
}

export class OnboardingCatalog{
    cluster : string;
    criteriaDescription : string;
    registerData : Date;
}

export enum OnboardingStepNumber{
    WELCOME_STEP = 0,
    PROJECT_INFORMATION_STEP = 1,
    MEETING_STEP = 2,
    CONFIGURATION_STEP = 3,
    ACCOUNT_RECEIVABLE_STEP = 4
}

export enum OnboardingStatus {
    PENDING_STATUS = "P",
    CONFIGURED_STATUS = "C",
    FINISHED_STATUS = "F",
    NOT_CONFIGURED_STATUS = "N",
}

export enum StatusCall{
    SCHELDULED_AGENDAD = 'S',
    RESCHEDULED_REAGENDADA = 'R',
    ATTENDED = 'A',
    NOT_ATTENDED = 'N'
}

export enum StepInternal {
    ONE_STEP = 1,
    TWO_STEP = 2,
    THREE_STEP = 3
}

export enum SubStepInternal{
    SCHEDULE_CALL_SUBSTEP = "SCHEDULE_CALL",
    UPLOAD_SUBSTEP = "UPLOAD",
    READY_SUBSTEP = "READY"
}

export enum IntegrationType {
    API_UPLOAD_DATA_TYPE = 'A',
    FILE_UPLOAD_DATA_TYPE = 'F',
    ERP_UPLOAD_DATA_TYPE = 'E',
    DONT_KNOW_UPLOAD_DATA_TYPE = 'D'
}

export enum CommunicationTone{
    INFORMAL = "I",
    FORMAL = "F",
}

export enum PrincipalPaymentCurrencyEnum {
    LOCAL = 'LOCAL',
    USD = 'USD'
}