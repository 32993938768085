import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../modules/auth';
import { ValidTokenResponse } from '../modules/auth/components/models/auth.token.valid.response.model';
import { OnboardingStatus, OnboardingStepNumber } from '../modules/warmi/onboarding/model/onboardingStep';
import { log } from 'console';

@Injectable({
    providedIn: 'root'
})
export class ActiveSessionGuard implements CanActivate {


    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        const wrapperSubject = new Subject<boolean>();
        this.authService.isValidToken().subscribe((res : ValidTokenResponse) => {
            const owner = this.authService.decryptedToken()?.owner;
            const isSuperAdmin = this.authService.decryptedToken()?.isSuperadmin;
            console.log(isSuperAdmin);
            if(res?.isValid) {
                if(res?.onboardingStatus == OnboardingStatus.FINISHED_STATUS ){
                    return wrapperSubject.next(true);
                }
                else if(!owner && !isSuperAdmin && !res.enablePlatform && res?.onboardingStatus == OnboardingStatus.CONFIGURED_STATUS){
                    this.router.navigateByUrl('/welcome-user')
                    console.log("Lo redirigue el GUARD");
                    return wrapperSubject.next(true);
                }
                else if(res?.currentStep == OnboardingStepNumber.WELCOME_STEP ){
                    this.router.navigateByUrl('/welcome');
                    return wrapperSubject.next(true);
                } 
                else {
                    return wrapperSubject.next(true);
                }
            }
            this.authService.clearCookiesAndLocalStorage();
            return wrapperSubject.next(false);
        }, (err : any)=> {
            this.authService.clearCookiesAndLocalStorage();
            return wrapperSubject.next(false);
        });
        return wrapperSubject;
    }

}
