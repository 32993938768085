import { DocumentType } from "./documentType.model";
export class Interaction{
  id:string;
  sendDate:string;
  clientId:string;
  projectId:string;
  communicationId:string;
  communicationTemplateId:string;
  communicationTemplateName:string;
  communicationChannelType:string;
  communicationFlowId:string;
  communicationFlowName:string;
  communicationCampaignId:string;
  communicationCampaignName:string;
  communicationInstantEventType:string;
  paymentOrderId:string;
  paymentActionId:string;
  paymentActionType:string;
  communicationType:string; // programada o instantanea
  lastStatus:number;
  registerDate:string;
  paymentActionOrigin : string;
  documentTypeId : number;
  documentNumber : string;
  nameClient : string;
  lastNameClient : string;
  externalClientId : string | null;
  documentType? : DocumentType;
  recipientType : string;
  extranetSessionId : string;
  paymentActionConfirmationStatus : string;
  conversationSkip : boolean;
  contactLevel : number;
  businessLegalNameClient : string;
  businessTradeNameClient : string;
  businessContactNameClient : string;
  businessContactLastNameClient : string;
  callManagementNote : string;
  callManagementAttachmentUrls : string[];
  recoveryCampaignId : string;
  recoveryCampaignPaymentActionId : string;
  recoveryCampaignPaymentActionType : string;
  recoveryCampaignPaymentActionOrigin : string;
  clientPortfolioType : string;
  extraData: ExtraDataDTO;
  failedCode : string;
  popOverDescription?: string | null;
  interactionStatusByExtraData?: String | null;
  clientVerificationProcessId : number | null;
  clientVerificationProcessStatus : string | null;
  clientVerificationProcessOrigin : string | null;
  inboundCommunicationId : string | null;
  inboundCommunicationChannelType : string | null;
  communicationBody : string | null;
  fieldManagementNote : string;
  fieldManagementAttachmentUrls : string[]
  lastPaymentActionId : string;
  lastPaymentActionType : string;
  lastPaymentActionDate : Date;
  lastCommunicationId : string;
  lastCommunicationChannelType : string;
  lastCommunicationStatus : number;
  lastCommunicationSendDate : Date;
  apiKeyId : string;
  apiKeyName : string;
  lastRecoveryCampaignPaymentActionId : string;
  lastRecoveryCampaignPaymentActionType : string;
  lastRecoveryCampaignPaymentActionDate : Date;
}

export class ExtraDataDTO{
  errorMessage: string;
  callManagementLastActionType : string;
  fieldManagementLastActionType : string;
  callManagementRetryAt : any;
  communicationFailedCode : string;
  callSeconds : number | null;
  paymentOrderReference: string[];
  recoveryCampaignReference: string[];
  clientNote:string;
  clientNoteLastActionType:string;
  clientNoteAttachmentUrls:string[];
  verificationProcessDenialReason : string | null;
  paymentActionNote : string | null;
}

export enum InteractionCommunicationType{
  MANUAL_TYPE = "M"
}

export enum InstantEventEnum{
  INSTANT_EVENT_PAYMENT_COMMITMENT_DAY_ZERO = "D0",
  INSTANT_EVENT_PAYMENT_COMMITMENT_ONE_DAY_BEFORE = "D1",
  INSTANT_EVENT_PAYMENT_COMMITMENT_THREE_DAYS_BEFORE = "D3",
  INSTANT_EVENT_PAYMENT_COMMITMENT_ONE_DAY_AFTER = "AD1",
  INSTANT_EVENT_VERIFY_PAYMENT = "VP",
  INSTANT_EVENT_NEW_PAYMENT_ORDER = "NPO",
  INSTANT_EVENT_PARTIAL_PAYMENT = "PP",
  INSTANT_EVENT_TOTAL_PAYMENT = "TP",
  INSTANT_EVENT_PAYMENT_NOT_CONFIRMED = "NC",
  INSTANT_EVENT_RECOVERY_CAMPAIGN_FIRST_COMMUNICATION = "RCFC",
  INSTANT_EVENT_RECOVERY_CAMPAIGN_PAYMENT_CONFIRMATION = "RCPC",
  INSTANT_EVENT_RECOVERY_CAMPAIGN_VERIFY_PAYMENT = "RCVP",
  INSTANT_EVENT_NEW_RECOVERY_CAMPAIGN = "NRC",
  INSTANT_EVENT_RECOVERY_CAMPAIGN_PARTIAL_PAYMENT = "RCPP",
  INSTANT_EVENT_RECOVERY_CAMPAIGN_TOTAL_PAYMENT = "RCTP",
  INSTANT_EVENT_RECOVERY_CAMPAIGN_PAYMENT_NOT_CONFIRMED = "RCNC",
  INSTANT_EVENT_RECOVERY_CAMPAIGN_PAYMENT_COMMITMENT_DAY_ZERO = "RCD0",
  INSTANT_EVENT_RECOVERY_CAMPAIGN_PAYMENT_COMMITMENT_ONE_DAY_BEFORE = "RCD1",
  INSTANT_EVENT_RECOVERY_CAMPAIGN_PAYMENT_COMMITMENT_THREE_DAYS_BEFORE = "RCD3",
  INSTANT_EVENT_RECOVERY_CAMPAIGN_PAYMENT_COMMITMENT_ONE_DAY_AFTER = "ARCD1",
  INSTANT_EVENT_FIRST_COMMUNICATION = "FC",
  INSTANT_EVENT_PAYMENT_CONFIRMATION = "PC",
}

export enum InteractionStatusEnum{
  INTERACTION_STATUS_SEND = 1,
  INTERACTION_STATUS_DELIVERY = 2,
  INTERACTION_STATUS_OPEN = 3,
  INTERACTION_STATUS_GENERATED = 4,
  INTERACTION_STATUS_PROGRAMMED = 5,
  INTERACTION_STATUS_FAILED = 6,
  INTERACTION_STATUS_CALL_MANAGMENT_CONTACT = 7,
  INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT = 8,
  INTERACTION_STATUS_CALL_MANAGMENT_TO_MANAGE = 9,
  INTERACTION_STATUS_MISS_OUT = 10,
  INTERACTION_STATUS_SKIPPED = 11,
  INTERACTION_STATUS_CALL_MANAGEMENT_INBOUND_CONTACT = 12,
  INTERACTION_STATUS_SENT_NO_STATUS = 13,
  INTERACTION_STATUS_CALL_MANAGMENT_PHONECALL_NO_MANAGEMENT = 14,
  INTERACTION_STATUS_IN_PROCESS = 15,
  INTERACTION_STATUS_NO_RESPONSE_FROM_PROVIDER = 16,
  INTERACTION_STATUS_CONTACT = 17,
  INTERACTION_STATUS_NOT_CONTACT = 18,
  INTERACTION_STATUS_INBOUND = 19,
}

export enum CommunicationType{
  INBOUND_TYPE = "I",
  OUTBOUND_TYPE = "O",
}

export class InteractionPageable{
  last : boolean
  totalPages : number;
  numberOfElements : number;
  total : number;
  content : Interaction[];
}